import { graphql, useStaticQuery } from 'gatsby';

export const useResources = () => {
  const { allWpPost } = useStaticQuery(graphql`
    query AllResources {
      allWpPost(sort: { fields: date, order: ASC }) {
        nodes {
          ...PostPreview
        }
      }
      allWpCategory {
        nodes {
          name
          uri
        }
      }
    }
  `);

  return allWpPost?.nodes;
};

export default useResources;
