import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { PrimaryTextColour, SecondaryTextColour } from '~/components/utility/TextColour';

export const CardStackCard = ({
	background,
	subheading,
	heading,
	content,
	imageOffset,
	imageWidth,
	image,
	imageFirst = true,
	link,
}) => {
	let bgColourClass = 'bg-pampas';
	{
		background && (bgColourClass = background);
	}
	let imageClasses = [''],
		imageContainerClasses = ['relative h-full'],
		containerClasses = ['container !px-8 lg:px-20 relative'],
		textInnerClasses = ['max-w-[412px] text-center md:text-left mt-4 lg:mt-0'];
	// apply grid to containing element and centre text within grid col
	if (image) {
		containerClasses.push('grid md:grid-cols-2 gap-8 md:gap-x-14 lg:gap-x-32 items-center');
		textInnerClasses.push('mx-auto md:ml-0');
	}
	// if image but not offset or no image, apply padding to container
	if ((image && !imageOffset) || !image) {
		containerClasses.push('py-10 md:py-10 lg:py-20');
	}
	if (imageFirst === false) {
		imageContainerClasses.push('md:order-last');
	}
	// max width for image
	if (imageWidth) {
		imageClasses.push(imageWidth);
	}
	if (imageOffset) {
		if (imageOffset === 'bottom') {
			imageContainerClasses.push('md:top-16');
			imageClasses.push('md:absolute -bottom-6');
		} else {
			imageContainerClasses.push('md:bottom-16');
			imageClasses.push('md:absolute -top-6');
		}
	}
	// apply padding to text block if image is offset
	if (image && imageOffset) {
		textInnerClasses.push('py-8 md:py-10 lg:py-16');
	}

	return (
				<div className={'md:sticky top-[3rem] md:top-[4rem] lg:top-[6rem] md:h-[50vh] lg:h-[60vh] md:min-h-[450px] lg:min-h-[600px] md:flex md:items-center rounded-lg container-lg !max-w-[1328px] ' + bgColourClass + ' ' + PrimaryTextColour(background)}>
					<div className={containerClasses.join(' ')}>
						{image && (
							<figure className={imageContainerClasses.join(' ')}>
								<Image image={image} className={`${imageClasses.join(' ')}  max-w-[460px] mx-auto md:mr-0`}/>
							</figure>
						)}
						<div>
							<div className={textInnerClasses.join(' ')}>
								{/* {subheading && <p className={'font-bold mb-4 text-[18px] ' + SecondaryTextColour(background)}>{subheading}</p>} */}
								<h2 className="text-h3 mb-4 max-w-[410px]">{heading}</h2>
								<div className="text-[17px] mx-auto md:ml-0 max-w-[374px]" dangerouslySetInnerHTML={{ __html: content }} />
								{link && (
									<Link
										className={'mt-4 font-bold ' + SecondaryTextColour(background)}
										title={link.title}
										iconAfter
										url={link.url}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
	);
};
