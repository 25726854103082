import React from 'react';
import { Image } from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const ImageRows = ({ imageRows }) => {
  return (
    <InViewAnimator className="container"
    inner={(controls) => (
      <>
        <ul className="space-y-5">
          {imageRows?.map((listItem, i) => (
            <InViewAnimatorBasicItem key={i} className="items-center gap-10 p-10 text-center rounded-lg lg:p-18 bg-pampas lg:text-left lg:flex"
              controls={controls}
              delay={i/6}
              duration={1.5}
            >
              {listItem.image && (
                <figure className="mb-6 lg:mb-0 lg:ml-10 lg:w-[17%] inline-block w-[130px]">
                  <Image image={listItem.image} />
                </figure>
              )}
              <header className="my-2 lg:w-[34%]">
                <h3 className="max-w-md text-h4">{listItem.heading}</h3>
              </header>
              <div className="lg:w-[50%]">
                <p className="max-w-md lg:text-lg">{listItem.content}</p>
              </div>
            </InViewAnimatorBasicItem>
          ))}
        </ul>
      </>
    )}
    />
  );
};
