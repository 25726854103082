import React from 'react';
import { Image } from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const Stats = ({ background, statSize, heading, content, image, listItems, twoColumns }) => {
  if (twoColumns) {
    return (
      <section className="section">
        <div className="container-s-lg py-4 lg:py-10 xl:py-20 rounded-lg relative bg-pampas !mx-4 md:!mx-auto">
          <div className="gap-6 md:flex md:gap-10 lg:gap-14 xl:px-16">
            <div className="my-4 md:w-1/2">
              <div className="max-w-sm">
                {heading && <h2 className="text-h4 !font-body !font-bold mb-6">{heading}</h2>}
                {content && <div className="prose" dangerouslySetInnerHTML={{ __html: content }} />}
              </div>
            </div>
            <div className="md:w-1/2">
              <InViewAnimator className="grid-cols-2 gap-4 mt-8 sm:grid lg:gap-14 md:mt-0"
               inner={(controls) => (
                <>
                {listItems.map((listItem, i) => (
                  <InViewAnimatorBasicItem key={i} className="my-4"
                    controls={controls}
                    delay={i/6}
                    duration={1.5}
                  >
                    <div className="px-6 py-4 sm:max-w-[220px] border rounded-md  border-periwinkle md:border-0 lg:py-0 lg:px-0">
                      <h3 className="mb-1 text-h3 text-forest">{listItem.value}</h3>
                      <p>{listItem.description}</p>
                    </div>
                  </InViewAnimatorBasicItem>
                ))}
                </>
               )}
              />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="section section-stats">
        {statsContent(background, listItems, statSize, heading, image)}
      </section>
    );
  }
};

const statsContent = (background, listItems, statSize, heading, image) => {
  if (background) {
    return (
      <div className="container-lg">
        <div className={'section-md lg:py-24 rounded-lg relative ' + background}>
          <div className="container">
            {heading && <h2 className="max-w-2xl mx-auto mb-5 text-center text-h3 md:mb-10">{heading}</h2>}
            {listItems && <StatsList listItems={listItems} statClass={statSize} background={background} />}
            {image && (
              <figure className="w-[40px] md:w-[12%] lg:w-[147px] absolute bottom-[-12%] right-[8%]">
                <Image image={image} />
              </figure>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        {heading && <h2 className="max-w-2xl mx-auto mb-10 text-center text-h2">{heading}</h2>}
        {listItems && <StatsList listItems={listItems} statClass={statSize} />}
      </div>
    );
  }
};

const StatsList = ({ listItems, statClass, background }) => {
  let sc = '';
  if (statClass === 'large') {
    sc = 'lg:!text-7xl';
  } else {
    sc = 'lg:!text-[40px]';
  }
  return (
    <ul className="grid-cols-3 space-y-4 section-stats-list sm:grid sm:space-y-0">
      {listItems.map((listItem, i) => (
        <li key={i} className="text-center section-stats-list-item sm:px-4">
          <h3 className={'text-h4 text-forest mb-1 ' + sc}>{listItem.value}</h3>
          <p className={background ? 'md:text-lg' : 'md:text-lg md:max-w-[180px] mx-auto'}>{listItem.description}</p>
        </li>
      ))}
    </ul>
  );
};
