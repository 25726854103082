import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button } from '~/components/elements/button/Button';
import { HeroInner } from '~/components/flexible/Hero';

export const ArticleHero = ({
	background,
	image,
	imageFirst,
	preHeading,
	heading,
	headingSize,
	content,
	button,
	logo,
	logoItems,
	imageSize,
	link,
	date,
}) => {
	let bgClasses = '';
	if (background) {
		bgClasses = 'container-lg lg:px-16 section-md bg-pampas rounded-lg ' + background;
	} else {
		bgClasses = 'container';
	}
	let hSize = 'text-h1';
	if (headingSize) {
		hSize = headingSize;
	}
	return (
		<header className={background ? 'section pt-0 !pb-0' : 'section'}>
			<a href={link} title={heading} className={bgClasses + ' block'}>
				<HeroInner
					image={image}
					imageFirst={imageFirst}
					preHeading={preHeading}
					heading={heading}
					headingSize={hSize}
					content={content}
					button={button}
					// imageSize={imageSize}
					logo={logo}
					link={link}
					date={date}
				/>
			</a>
		</header>
	);
};
