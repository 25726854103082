import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { Icon, IconType } from '~/components/elements/Icon';

export const ComparisonTable = ({ columnOneHeading, listItems }) => {
  return <>{listItems && <TableContent listItems={listItems} columnOneHeading={columnOneHeading} />}</>;
};

export const TableContent = ({ listItems, columnOneHeading }) => (
  <div className="mt-16 container-xl">
    <div className="items-end hidden md:flex max-w-[1328px] mx-auto sticky top-0">
      <div className="w-1/5 text-center">&nbsp;</div>
      <div className="flex items-end justify-center w-2/5 py-8 leading-4 px-7 bg-pampas font-heading md:text-l lg:text-xl rounded-tl-md">
        {columnOneHeading}
      </div>
      <div className="flex items-end justify-center w-2/5 text-white px-7 py-9 bg-sea rounded-t-md">
        <i className="block fw-icon text-white w-[91px] h-[26px] lg:w-[116px] lg:h-[33px]">
          <Icon type={IconType.Logo} />
        </i>
      </div>
    </div>
    {listItems.map((listItem, i) => (
      <TableContentRow key={i} id={i} {...listItem} columnOneHeading={columnOneHeading} />
    ))}
  </div>
);

export const TableContentRow = ({ rowHeading, colOneContent, colTwoListItems, id, columnOneHeading }) => (
  <div className="md:flex max-w-[1328px] mx-auto">
    <div
      className={`md:flex items-center bg-pampas p-4 md:p-8 lg:pl-12 md:w-1/5 font-bold t-18 justify-center md:justify-start  border-r border-[#E4E1D8] ${
        id === 0 ? 'rounded-tl-md' : 'border-t border-t-dashed'
      }`}
    >
      {rowHeading}
    </div>
    <div
      className={`md:flex items-center bg-pampas p-4 md:p-8 lg:px-16 md:w-2/5 ${
        id === 0 ? 'bg-gradient-to-b from-[#E9E7E3] via-pampas to-pampas' : 'border-t border-[#E4E1D8] border-dashed'
      }`}
    >
      <h4 className="mb-3 text-lg md:hidden">{columnOneHeading}</h4>
      <p className="flex flex-start">
        <i className="block fw-icon text-ash mr-2 mt-[1.5px] flex-none w-[18px] h-[18px]">
          <Icon type={IconType.CircleLine} />
        </i>
        <span>{colOneContent}</span>
      </p>
    </div>
    <div
      className={`md:flex items-center bg-sea text-white p-4 md:p-8 lg:px-16 md:w-2/5 ${
        id === 0 ? 'bg-gradient-to-b from-[#163538] via-sea to-sea' : 'border-t border-white/10 border-dashed'
      }`}
    >
      <h4>
        <i className="block fw-icon text-white mb-4 w-[91px] h-[26px] md:hidden">
          <Icon type={IconType.Logo} />
        </i>
      </h4>
      <ul>
        {colTwoListItems?.map((colTwolistItem, j) => (
          <TableColTwoListItems key={j} {...colTwolistItem} />
        ))}
      </ul>
    </div>
  </div>
);

export const TableColTwoListItems = ({ heading, content }) => (
  <>
    <li className="flex items-start">
      <i className="block fw-icon text-forest mr-2 mt-[8px] flex-none w-[18px] h-[18px]">
        <Icon type={IconType.CircleTick} />
      </i>
      <div>
        {heading && <p className="my-1 text-lg font-bold">{heading}</p>}
        {content && <p className="my-1">{content}</p>}
      </div>
    </li>
  </>
);
