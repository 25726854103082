import { graphql, useStaticQuery } from 'gatsby';

export const useCustomerStories = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpCustomer {
        nodes {
          title
          uri
          postTypeCustomer {
            previewImage {
              localFile {
                publicURL
              }
              altText
            }
            previewContent
          }
        }
      }
    }
  `);

  return data.allWpCustomer?.nodes;
};
