import React, { useState } from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { ModalDialog } from '~/components/elements/ModalDialog';

export const ContactLanding = ({ image, subheading, heading, link, backgroundColour, listItems }) => {
	let bgc = 'from-[#faf8f4]';
	if (backgroundColour) {
		bgc = backgroundColour;
	}
	return (
		<header className="pt-28 pb-32 lg:pt-[12rem] lg:pb-40 section-background-image-hero relative z-1 overflow-hidden">
			{image && (
				<figure className="absolute bottom-0 left-0 w-full h-full overflow-hidden hero-image-wrapper z-1 ">
					<div className="absolute bottom-0 left-0 w-full h-full">
						<Image image={image} className="object-cover w-full h-full opacity-80 sm:opacity-100" />
					</div>
				</figure>
			)}
			<div className="container relative z-4 text-center section-dashboard-hero--text !max-w-[840px]">
				{subheading && <p className="font-bold text-forest mb-5 lg:text-[19px]">{subheading}</p>}
				<h1 className="text-h3">{heading}</h1>
				{listItems && (
					<nav className="flex flex-col sm:flex-row gap-6 mt-14 lg:mt-[74px]">
						{listItems.map((listItem, i) => (
							<ContactItem key={i} {...listItem} />
						))}
					</nav>
				)}
			</div>
		</header>
	);
};

export const ContactItem = ({ heading, content, image, link, modalTitle, form }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<button
			onClick={() => setIsOpen(true)}
			title={link.title}
			className="flex-col items-center justify-between block text-center transition-all duration-200 ease-in-out bg-white rounded-lg sm:flex ms:my-0 sm:w-1/2 p-9 lg:p-14 hover:scale-105"
		>
			{image && (
				<figure className="max-w-[96px] mx-auto mb-3">
					<Image image={image.localFile.publicURL} />
				</figure>
			)}
			<h3 className="text-h5 my-3 max-w-[260px] mx-auto">{heading}</h3>
			<p className="prose max-w-[260px] mx-auto">{content}</p>
			<ModalDialog isOpen={isOpen} setIsOpen={setIsOpen} title={modalTitle} image={image} form={form} />
			<p>
				<Link className="mt-6 font-bold md:mt-10 text-forest" faux title={link.title} url={link.url} iconAfter />
			</p>
		</button>
	);
};
