import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MastheadHome = ({ backgroundImage, mastheadImage, subheading, heading, content, links, backgroundColour = 'fog' }) => {
	let bgc = '',
		bgg = '';
	if (backgroundColour === 'fog') {
		bgc = 'bg-fog';
		bgg = 'from-fog';
	}
	return (
		<InViewAnimator className="pt-24 pb-12 -mb-8 overflow-hidden md:pt-40 md:pb-4 section-dashboard-hero"
		inner={(controls) => (
			<>
			<header className={`relative`}>
				{backgroundImage && (
					// translate-y-[20%] sm:translate-y-[44%] lg:translate-y-[60%] xl:translate-y-[50%]
					<figure className="hero-image-wrapper z-1 bottom-0 left-0 right-0 mx-auto absolute w-full h-full h-[260%] overflow-hidden translate-y-[40%]">
						<div className="absolute bottom-[-1px] left-0 w-full h-full">
							<Image image={backgroundImage} className="opacity-80 sm:opacity-100 !object-cover w-full h-full" />
							<div className="absolute w-full h-[15%] lg:h-[20%] bottom-[9%] lg:bottom-0 -mb-1 left-0 bg-gradient-to-t from-pearl z-2"></div>
							<div className="absolute w-full h-[10%] bottom-[-1%] left-0 bg-pearl z-2 lg:hidden"></div>
							{/* <div className={`absolute w-full h-[20%] top-0 left-0 bg-gradient-to-b ${bgg} z-2`}></div> */}
						</div>
					</figure>
				)}
				<InViewAnimatorBasicItem
						controls={controls}
						delay={0.2}
						prop="y"
						start={50}
						className="container relative text-center z-4 section-dashboard-hero--text">
					<div className="sm:px-[10%] lg:px-0 md:max-w-[714px] mx-auto">
						{subheading && <p className="font-bold text-forest mb-5 lg:text-[19px]">{subheading}</p>}
						<h1 className="text-h1">{heading}</h1>
						{content && <div className="mt-4 md:text-lg lg:text-xl" dangerouslySetInnerHTML={{ __html: content }} />}
						{links && (
							<>
								{links.map(({ link, type }, i) =>
									<Button
										className="mt-10 w-[220px] max-w-[100%] lg:w-[250px]"
										link={{ url: link.url, title: link.title, target: link.target }}
										type={type}
										color={ButtonColor.Forest}
										key={i}
									/>
								)}
							</>
						)}
					</div>
				</InViewAnimatorBasicItem>
			</header>
			{mastheadImage && (
				<InViewAnimatorBasicItem
				controls={controls}
				delay={0.4}
				prop="y"
				start={50} className="container">
					<div className="shadow-lg relative z-3 w-full rounded-md mt-28 lg:mt-[200px]">
						<Image image={mastheadImage} />
					</div>
				</InViewAnimatorBasicItem>
			)}
			</>
		)}
		/>
	);
};
