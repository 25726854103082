import React from 'react';
import { Image } from '~/components/elements/Image';

export const Testimonial = (props) => {
  const { image, name, jobTitle, content, imageBackground } = props?.selectedTestimonial?.postTypeTestimonial || {};
  return (
    <div className="text-center px-5 py-10 md:py-16  md:py-[145px]">
      <div>
        <figure className="max-w-2xl mx-auto">
          {content && <blockquote className="mb-3 text-h4">{content}</blockquote>}
          {personContent(image, name, jobTitle, imageBackground)}
        </figure>
      </div>
    </div>
  );
};

const personContent = (image, name, jobTitle, imageBackground) => {
  let secondaryTextColour = 'opacity-80';
  const bgColour =[];

  if (imageBackground === 'white') {
  } else if (imageBackground === 'pearl') {
    bgColour.push('bg-pearl');
  } else if (imageBackground === 'sea') {
    bgColour.push('bg-sea');
  } else if (imageBackground === 'periwinkle') {
    bgColour.push('bg-periwinkle');
  } else if (imageBackground === 'iceberg') {
    bgColour.push('bg-iceberg');
  } else if (imageBackground === 'glacier') {
    bgColour.push('bg-glacier');
  } else if (imageBackground === 'oyster') {
    bgColour.push('bg-oyster');
  } else if (imageBackground === 'Fog') {
    bgColour.push('bg-Fog');
  } else if (imageBackground === 'pampas') {
    bgColour.push('bg-pampas');
  } else if (imageBackground === 'fountain') {
    bgColour.push('bg-fountain');
  } else if (imageBackground === 'lavender') {
    bgColour.push('bg-lavender');
  } else if (imageBackground === 'turq') {
    bgColour.push('bg-turq');
  } else if (imageBackground === 'forest') {
    bgColour.push('bg-forest');
  }

  if (image && name) {
    return (
      <div className="flex justify-center mt-6 md:mt-12">
        <figure className={`w-[50px] h-[50px] mr-3 md:mr-6 pt-[5px] rounded-full overflow-hidden safari-overflow ${bgColour}`}>
          <Image image={image} />
        </figure>
        <figcaption className="flex flex-col justify-start text-left">
          <span>{name}</span>
          {jobTitle && <span className={secondaryTextColour}>{jobTitle}</span>}
        </figcaption>
      </div>
    );
  }
  if (name && !image) {
    return (
      <div className="mt-12 text-center">
        <figcaption className="flex flex-col">
          <span>{name}</span>
          {jobTitle && <span className="opacity-80">{jobTitle}</span>}
        </figcaption>
      </div>
    );
  }
};
