import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { QuoteContent } from '~/components/flexible/QuoteFeature';

export const FeaturedCustomer = ({
  image,
  imageFirst,
  logo,
  heading,
  link,
  quote,
  personImage,
  personName,
  personRole,
}) => {
  return (
    <div className={'container md:gap-12 md:flex items-center ' + (!imageFirst && 'md:flex-row-reverse')}>
      {image && (
        <figure className="max-w-[612px] w-full rounded-lg overflow-hidden my-6">
          <Image image={image} />
        </figure>
      )}
      <div className="md:w-1/2 lg:w-2/5 my-6">
        <div className="max-w-[443px]">
          {logo && (
            <figure className="w-[82px] mb-6">
              <Image image={logo} />
            </figure>
          )}
          <h2 className="text-h5 mb-4">{heading}</h2>
          {link && <Link className="font-bold text-forest" title={link.title} iconAfter url={link.url} />}
          {quote && (
            <div className="mt-8">
              <hr className="border-ash" />
              <figure className="mt-8">{QuoteContent(quote, personImage, personName, personRole)}</figure>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
