import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { Icon, IconType } from '~/components/elements/Icon';
import { Button } from '~/components/elements/button/Button';
import { DemoRequestForm } from '~/components/elements/forms/DemoRequestForm';

export const DemoRequest = (props) => {
  const { image, heading, content, listItems, logoItems, formHeading, formIcon } = props.demoRequest;

  let bgc = 'from-[#faf8f4]';
  return (
    <section className="relative overflow-hidden section-lg z-1 section-background-image-hero">
      {image && (
        <figure className="absolute bottom-0 left-0 w-full h-full overflow-hidden hero-image-wrapper z-1 ">
          <div className="absolute bottom-0 left-0 w-full h-full">
            <Image image={image} className="object-cover w-full h-full opacity-50 sm:opacity-100" />
            <div className="absolute w-full h-[20%] bottom-0 left-0 bg-gradient-to-t from-pearl z-2"></div>
            <div className={`absolute w-full h-[20%] top-0 left-0 bg-gradient-to-b ${bgc} z-2`}></div>
          </div>
        </figure>
      )}
      <div className="container relative items-center gap-10 space-y-10 md:flex md:space-y-0 z-3">
        <div className="md:w-1/2">
          <h1 className="mb-6 text-h3">{heading}</h1>
          {content && <p className="text-[17px] lg:text-[19px] max-w-md">{content}</p>}
          {listItems && (
            <ul className="mt-6 lg:mt-14">
              {listItems.map((listItem, i) => (
                <li className="flex flex-wrap items-center w-full gap-5 my-4 sm:no-wrap lg:my-4" key={i}>
                  <div>
                    <figure className=" flex justify-center items-center bg-transparent  w-[46px] h-[46px] lg:w-[50px] lg:h-[50px] ">
                      <Image image={listItem.image} className="max-w-[46px] lg:max-w-[50px] max-h-[35px] lg:max-h-[40px] w-full h-full" />
                    </figure>
                  </div>
                  <p className="text-lg font-heading lg:text-xl">{listItem.heading}</p>
                </li>
              ))}
            </ul>
          )}
          {logoItems && (
            <div className="hidden lg:block mt-9 lg:mt-16 md:max-w-[416px] py-4 rounded-lg bg-pearl border-2 border-pampas">
              <div className='flex items-center justify-center px-6 py-2 space-x-6 md:px-8'>
                {logoItems.map((logoItem, i) => (
                  <div className="w-[88px] h-[25px]">
                    <Image image={logoItem.image} key={i} className="object-contain w-full h-full" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="md:w-1/2">
          <div className="w-full p-6 bg-white border-2 rounded-md lg:p-10 border-pampas">
            {formHeading && (
              <h2 className="hidden lg:block font-body mb-10 font-bold text-xl lg:text-[28px] text-center">{formHeading}</h2>
            )}
            <DemoRequestForm image={formIcon}/>
          </div>
          {logoItems && (
            <div className="lg:hidden mt-9 lg:mt-16 md:max-w-[416px] py-4 rounded-lg bg-pearl border-2 border-pampas">
              <div className='flex items-center justify-center px-6 py-2 space-x-6 md:px-8'>
                {logoItems.map((logoItem, i) => (
                  <div className="w-[88px] h-[25px]">
                    <Image image={logoItem.image} key={i} className="object-contain w-full h-full" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
