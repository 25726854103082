import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { Button } from '~/components/elements/button/Button';
import { PressForm } from '~/components/elements/forms/PressForm';
import { SalesForm } from '~/components/elements/forms/SalesForm';
export const ModalDialog = ({ title, isOpen, setIsOpen, image, form }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} as="div"  className="fixed inset-0 z-10 overflow-y-auto px-6">
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
            <div className="relative bg-white rounded-lg max-w-[588px] w-full mx-auto p-6 pt-8 md:p-10 md:pt-12 mt-12 text-center">
              <Dialog.Title className="text-[20px] md:text-[28px] font-body font-bold mb-8 md:mb-10">{title}</Dialog.Title> 
              {form === 'press' ? (
                <PressForm image={image} />
              ) : (
                <SalesForm image={image} />
              )} 
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
