import * as React from 'react';
import { Link } from '~/components/elements/Link';
import { useEffect } from 'react';
import { useState } from 'react';

export const Legal = (page) => {
  const legal = page.legal;
  const section = page.legal.section;

  const refs = [];
  const [active, setActive] = useState(0);

  useEffect(() => {
    refs.reverse();
    const onScroll = (e) => {
      if (typeof window === undefined) return;
      const scrollY = e.target.documentElement.scrollTop;
      for (let i in refs) {
        const ref = refs[i];
        if (!ref.current) break;
        const top = window.pageYOffset + ref.current.getBoundingClientRect().top - 400;
        if (scrollY > top) {
          setActive(refs.length - i - 1);
          break;
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <div className="container section-lg">
        <header className="mb-10 md:mb-20 lg:mb-[160px] max-w-4xl">
          <p className="mb-4 font-bold text-forest lg:text-lg">{legal.subheading}</p>
          <h1 className="text-h1">{legal.heading}</h1>
          <p className="mt-8 text-lg">Last updated {legal.date}</p>
        </header>
        <div className="grid-cols-12 gap-12 md:grid lg:gap-24 scroll-smooth">
          <div className="mt-10 mb-8 md:col-span-6 md:order-1 lg:col-span-4 md:my-0">
            <nav className="bg-white rounded-lg px-6 py-4 lg:px-11 lg:py-8 border border-periwinkle">
              <ul>
                {section.map(({ label }, i) => {
                  return (
                    <li className="w-full py-2" key={i}>
                      <Link
                        className={`text-tiny lg:text-lg font-bold leading-tight w-full !flex justify-between text-[#A4B3B4] hover:text-forest transition-all ${
                          active === i ? '!text-forest ' : ''
                        }`}
                        title={label}
                        url={`#section-${i}`}
                        iconAfter
                      />
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div className="space-y-10 md:order-2 md:col-span-6 lg:col-span-8">
            {section.map(({ content }, i) => {
              const ref = React.useRef();
              refs.push(ref);
              return (
                <div
                  ref={ref}
                  id={`section-${i}`}
                  className="body-copy prose lg:text-[18px]"
                  key={i}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
