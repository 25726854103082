import React from 'react';
import { Link } from '~/components/elements/Link';

export const TextContent = ({
  subheading,
  heading,
  content,
  link,
  centered,
  maxWidth,
  contentMaxWidth,
  headingSize,
  contentSize,
}) => {

  let headingFont =[];
  if (headingSize === '2') {
    headingFont.push('text-h2 lg:text-h1');
  } else if (headingSize === '3'){
    headingFont.push('text-h2');
  } else {
    headingFont.push('text-h3');
  }
  return (
    <div className={`container ${centered ? 'text-center' : 'text-center md:text-left'}`}>
      <div
        className={`${!maxWidth ? 'max-w-xl' : ''} mr-auto ${centered ? 'ml-auto' : ''}`}
        {...maxWidthProps(maxWidth)}
      >
        {subheading && <p className="mb-4 font-bold text-forest content-subheading">{subheading}</p>}
        {heading && <h3 className={ headingFont + ` mb-4`}>{heading}</h3>}
        {content && (
          <p className={`${contentMaxWidth} ${contentSize === 'large' ? 'md:text-[19px]' : ''} t-17 prose mb-4 mr-auto ${centered ? 'ml-auto' : ''}`}>{content}</p>
        )}
        {link.link && (
          <Link
          className={'relative z-10 mt-6 py-2 text-forest text-lg font-bold transition-all duration-500 rounded-full md:whitespace-nowrap leading-1 hover:px-4  hover:text-pampas hover:bg-forest'}
          title={link.link.title}
          url={link.link.url} 
          iconAfter
        />
        )}
      </div>
    </div>

  );
};

export const maxWidthProps = (maxWidth) => {
  if (!maxWidth) return {};
  return {
    style: {
      maxWidth: `${maxWidth}px`,
    },
  };
};
