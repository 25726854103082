import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from '~/components/elements/Link';

export const CareersListings = (page) => {
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    axios
      .get('https://jobs.lever.co/v0/postings/haven?mode=json')
      .then(function (res) {
        setJobs(res?.data);
      })
      .catch(function (error) {
        console.log(error.toJSON());
      });
  }, []);

  return (
    <div className="container mt-8 space-y-6 md:mt-12">
      <div className="items-center justify-end w-full md:w-auto md:flex">
        <p className="my-4 text-forest">{jobs?.length} roles available</p>
      </div>
      <ul className="mt-6">
        {jobs?.map((item, i) => (
          <li className="w-full my-4 text-center transition-all duration-200 bg-white border border-white rounded-lg lg:text-left hover:border-forest" key={i}>
            <a
              className="flex flex-col w-full p-4 md:flex-row md:px-10 md:py-6 md:gap-4 md:items-center md:text-lg"
              target="#"
              href={item.hostedUrl}
              title={item.text}
            >
              <div className="my-1 font-bold md:w-1/3">
                {item.text && <p className="text-[18px] lg:text-lg font-bold">{item.text}</p>}
              </div>
              <div className="justify-center md:w-1/3 md:flex text-mineral">{item.categories.location}</div>
              <div className="items-center justify-end my-1 md:flex md:w-1/3">
                <Link
                  className="text-lg font-bold text-forest"
                  faux
                  title={'Apply now'}
                  url={item.hostedUrl}
                  iconAfter
                />
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
