import React from 'react';
import { Link } from '~/components/elements/Link';
import { Image } from '~/components/elements/Image';
import { Icon, IconType } from '~/components/elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const Team = ({ teamMembers }) => {
	return (
			<div className="container">
				{teamMembers && (
					<InViewAnimator className="grid grid-cols-2 gap-4 md:gap-6 md:grid-cols-3 lg:grid-cols-4"
					inner={(controls) => (
						<>
							{teamMembers.map((listItem, i) => (
								<PersonItem key={i} {...listItem} controls={controls} time={i} />
							))}
						</>
					)}
					/>
				)}
			</div>
	);
};

export const PersonItem = ({ name, role, image, linkedin, controls, time }) => {
	return (
		<InViewAnimatorBasicItem className="relative overflow-hidden rounded-lg bg-deepsea safari-overflow"
			controls={controls}
			delay={time/6}
			duration={1.5}
		>
			{image && (
				<figure className="w-full">
					<Image image={image} />
				</figure>
			)}
			<div className={'p-4 md:p-7 text-white text-center md:text-left ' + (linkedin ? 'md:flex gap-4 justify-between items-center' : '')}>
				<header>
					<h4 className="text-lg lg:text-xl xl:text-[24px]">{name}</h4>
					<p className="text-patina text-[14px] leading-tight md:text-[16px] mt-[4px]">{role}</p>
				</header>
				{linkedin && (
					<a href={linkedin.url} target={linkedin.target} title={name + ' LinkedIn'} className="absolute top-0 right-0 inline-block mt-4 mr-4 transition-all md:mr-0 md:mt-0 hover:text-fountain md:relative">
						<i className="inline-block w-[20px] h-[20px] md:w-[22px] md:h-[22px] fw-icon">
							<Icon type={IconType.LinkedIn} />
						</i>
					</a>
				)}
			</div>
		</InViewAnimatorBasicItem>
	);
};
