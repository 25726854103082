import React from 'react';
import { Image } from '~/components/elements/Image';

export const MediaAsset = ({ mediaType, image, secondaryMedia }) => {
  if (mediaType === '1')
    return (
      <div className="relative">
        <div className="px-5 lg:px-0">
          <Image image={image} className="w-full mx-auto max-w-[933px]" />
        </div>
        {secondaryMedia && (
          <Image
            image={secondaryMedia}
            className="w-full mx-auto !absolute bottom-[-30px] md:bottom-[-60px] right-[9%] max-w-[90px] md:max-w-[147px]"
          />
        )}
      </div>
    );
};
