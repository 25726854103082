import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";
import {Section} from "~/components/elements/Section"
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';
import { WpPage_Page_FlexibleContent } from "~/_generated/types";

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function defaultPage({
  data: { wpPage, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {

let ogImage = '';
if (wpPage && wpPage.seo.opengraphImage) {
  // @todo CHANGE THIS
  ogImage = 'https://haven.and.together.agency' + wpPage.seo.opengraphImage.localFile.publicURL;
}
  return (
    <Layout
      wp={wp}
      page={wpPage}
    >
       {wpPage && (
        <Seo
          post={wpPage}
          meta={[
            {
              name: 'og:image',
              content: ogImage,
            },
          ]}
        />
      )} 
      <LayoutRenderer
        prefix="Page_Posttypepage_Sections_Section_Components_"
        sections={wpPage?.postTypePage?.sections}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Default($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
