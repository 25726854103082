import React from 'react';
import { Image } from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const FeaturedIconGrid = ({ gridItems }) => {
  return <div className="container">{gridItems && <MultiGridFeaturesList listItems={gridItems} />}</div>;
};

export const MultiGridFeaturesList = ({ listItems }) => {
  return (
    <InViewAnimator className="md:grid grid-cols-12 gap-6 md:gap-10 lg:gap-14 md:gap-y-[100px]"
    inner={(controls) => (
      <>
        {listItems.map((listItem, i) => (
          <MultiGridFeaturesListItem key={i} id={i} {...listItem} time={i} controls={controls} />
        ))}
      </>
     )}
    />
  );
};

export const MultiGridFeaturesListItem = ({ icon, subheading, heading, content, id, time, controls }) => {
  return (
    <InViewAnimatorBasicItem className={'sm:col-span-6 my-10 text-center ' + (id >= 2 ? 'md:col-span-4 md:text-left' : '')}
      controls={controls}
      delay={time/6}
      duration={1.5}
    >
      {icon.localFile.publicURL && (
        <figure
          className={
            'flex items-center mb-6 mx-auto w-[113px] h-[75px] ' +
            (id >= 2 ? 'md:mx-0 justify-start items-start' : 'justify-center md:w-[306px] md:h-[263px] md:mb-12')
          }
        >
          <Image image={icon.localFile.publicURL} className="max-h-full max-w-full w-auto h-auto" />
        </figure>
      )}
      <div className={'max-w-sm mx-auto ' + (id >= 2 ? 'md:mx-0' : '')}>
        <Copy id={id} subheading={subheading} heading={heading} content={content} />
      </div>
    </InViewAnimatorBasicItem>
  );
};

const Copy = ({ subheading, heading, content, id }) => {
  if (id >= 2) {
    return (
      <>
        <p className="lg:text-lg md:max-w-[320px]">
          {subheading && <span className="font-bold text-forest mb-4">{subheading}</span>}
          {heading && <span className="font-heading font-bold">{heading}&nbsp;</span>}
          {content && <span>{content}</span>}
        </p>
      </>
    );
  } else {
    return (
      <>
        {subheading && <p className="font-bold text-forest mb-4">{subheading}</p>}
        {heading && <h3 className="text-h5 mb-4">{heading}</h3>}
        {content && <p>{content}</p>}
      </>
    );
  }
};
