import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useInViewStart } from '~/hooks/useInViewStart';
import { useAutoProgressTimeout } from '~/hooks/useAutoProgressTimeout';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TextContent } from '~/components/flexible/TextContent';
import { ChevronDown } from '~/components/elements/Icon';
import { HeightFixer } from '~/components/elements/Animator';
import 'swiper/css';
import { EffectFade } from 'swiper';
import 'swiper/css/effect-fade';
import { Image } from '~/components/elements/Image';

export const Accordion= ({ accordion, subheading, heading, }) => {
  const [ref, started] = useInViewStart();
  const frameLength = 7;
  const [active, set_active] = useAutoProgressTimeout(started, frameLength, accordion.length - 1);
  const gridClasses = ['flex flex-col md:flex-row items-center xl:items-stretch'];
  const imageClasses = ['max-w-[700px] max-h-[518px] order-last md:order-first'];

  imageClasses.push('md:mr-10 xl:mr-[75px] w-[96%] md:w-[50%] xl:w-[65%] mx-auto');
  

  return (
    <div className="container center-swiper">
      <div ref={ref} className={gridClasses.join(' ')}>
        <div className={imageClasses.join(' ')}>
          <CarouselSwiper items={accordion} active={active} set_active={set_active} />
        </div>
        <div className="flex flex-col flex-1 lg:max-w-[410px] mt-8 lg:mt-6 xl:mt-8">
          {heading && (
             <div className="">
             {subheading && <p className="font-bold text-forest mb-4">{subheading}</p>}
             <h2 className="text-h3 mb-4">{heading}</h2>
           </div>
          )}
          <HeightFixer className="pt-4" extraHeight={130} extraHeightMobile={60}>
            {accordion.map((item, i) => {
              return (
                <CarouselItem
                  key={i}
                  onClick={() => {
                    set_active(i);
                  }}
                  isActive={i === active}
                  item={item}
                  frameLength={frameLength}
                />
              );
            })}
          </HeightFixer>
        </div>
      </div>
    </div>
  );
};

export const CarouselSwiper = ({ items, active, set_active }) => {
  const [swiper, set_swiper] = useState({});

  useEffect(() => {
    if (swiper.on) {
      swiper.on('slideChange', function () {
        set_active(swiper.activeIndex);
      });
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper.slideTo && swiper.activeIndex !== active) {
      swiper.slideTo(active);
    }
  }, [swiper, active]);

  return (
    <div className="relative overflow-hidden bg-pampas rounded-lg py-6 px-6 md:py-12 lg:py-16">
      <Swiper
        onInit={(ev) => {
          set_swiper(ev);
        }}
        modules={[EffectFade]}
        effect="fade"
        fadeEffect={{ crossFade: items[0]?.image?.type === 'lottie' ? false : true }}
        spaceBetween={0}
        slidesPerView={1}
      >
        {items.map((item, i) => (
          <SwiperSlide key={i}>
            <Image accordionStyle image={item.image} className='max-w-[280px] md:max-w-[530px] max-h-[400px] w-full h-full mx-auto' active={active} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const CarouselItem = ({ item, isActive, onClick, frameLength }) => {
  return (
    <div className={`relative pl-12 mb-4 cursor-pointer lg:pl-16 md:mb-8 group ${isActive && 'bg-pampas p-6 rounded-[6px]'}`} onClick={onClick}>
      {isActive && (
        <div className="ml-12 md:ml-14 h-[1px] absolute bottom-0 left-0 right-0">
          <AnimatePresence>
            {isActive && (
              <motion.div
                initial={{ width: 0, opacity: 1 }}
                animate={{ width: '100%', opacity: 1 }}
                exit={{ opacity: 0, duration: 0.5 }}
                transition={{
                  width: {
                    duration: frameLength,
                  },
                  opacity: {
                    duration: 1,
                  },
                }}
                className={`h-full top-[100%] left-6`}
              ></motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
      <div className="w-[20px] md:w-[24px] mt-[-1px] absolute left-4 lg:left-6">
        <Image image={item.icon} />
      </div>
      <h4 className="relative flex items-center mb-1 space-x-3 text-base font-body font-bold duration-500 xl:space-x-5">
        <span>
          {item.heading}
        </span>
        <span className={`hidden md:inline-block transition-transform duration-500 ${isActive ? 'rotate-180' : ''}`}>
          <ChevronDown />
        </span>
      </h4>
      <AnimatePresence>
        {isActive && (
          <motion.div
            transition={{ type: 'tween' }}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1, duration: 1.5 }}
            exit={{ height: 0, opacity: 0, duration: 0.5 }}
          >
            <p className="relative pt-1 text-base">{item.content}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
