export const PrimaryTextColour = (background) => {
	let colourClass = '';

	switch (background) {
		case 'bg-fountain':
			colourClass = 'text-deepsea';
			break;
		case 'bg-sea':
			colourClass = 'text-white';
			break;
		case 'bg-deepsea':
			colourClass = 'text-white';
			break;
		case 'bg-lavender':
			colourClass = 'text-deepsea';
			break;
	}

	return colourClass;
};

export const SecondaryTextColour = (background) => {
	let colourClass = '';

	switch (background) {
		case 'bg-fountain':
			colourClass = 'text-forest';
			break;
		case 'bg-sea':
			colourClass = 'text-fountain';
			break;
		case 'bg-deepsea':
			colourClass = 'text-fountain';
			break;
		case 'bg-lavender':
			colourClass = 'text-deepsea/[.8]';
			break;
	}

	return colourClass;
};
