import React from 'react';
import { Image } from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const GridImageText = ({ gridItems, heading }) => {
  return (
    <InViewAnimator className="container"
    inner={(controls) => (
      <>
      {heading && <h2 className="text-h3 mb-10 lg:mb-20 max-w-[768px]">{heading}</h2>}
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {gridItems.map((listItem, i) => (
          <InViewAnimatorBasicItem key={i} className="p-8 text-center rounded-lg md:p-8 bg-pampas md:text-left"
            controls={controls}
            delay={i/6}
            duration={1.5}
          >
            {listItem.image && (
              <div className="max-w-[100px] md:max-w-[140px] h-[90px] md:h-[100px] mx-auto md:ml-0">
                <Image image={listItem.image.localFile.publicURL} className=" max-w-[100px] md:max-w-[140px] max-h-[100px] w-full h-full object-contain" />
              </div>
            )}
            <h3 className="text-h5 mt-6 md:mt-9 lg:text-[28px]">{listItem.heading}</h3>
          </InViewAnimatorBasicItem>
        ))}
      </ul>
      </>
    )}
    />
  );
};
