import React from 'react';
import Marquee from 'react-fast-marquee';
import { Image } from '~/components/elements/Image';

export const LogoSlider = ({ image, marquee, fullWidth, logos, logoSize = 'small' }) => {
  let containerClasses = [''];
  if (!fullWidth) {
    containerClasses.push('container');
  }
  if (logoSize === 'large' && marquee) {
    containerClasses.push('large-logos');
  }
  return (
    <div className={containerClasses.join(' ')}>
      {logos && <LogosInner logos={logos} marquee={marquee} logoSize={logoSize} />}
    </div>
  );
};

export const LogosInner = ({ logos, marquee, logoSize = 'small', gap = 'large' }) => {
  let wrapperClasses = '';
  let g = '';
  if (gap === 'large') {
    g = 'gap-5 md:gap-10 lg:gap-20';
  }
  if (gap === 'small') {
    g = 'gap-5 md:gap-7';
  }
  if (logoSize === 'large') {
    wrapperClasses = 'w-[260px] flex justify-center items-center py-3 bg-pampas rounded-md';
  }
  if (marquee) {
    return (
      <Marquee gradient={false} speed={40} gradient="true" gradientColor="[249, 247, 243]">
        {logos.map((listItem, i) => (
          <div
            className={
              'w-full flex mx-2 md:mx-5 my-4 ' +
              (logoSize === 'large' ? wrapperClasses : 'justify-between items-center')
            }
            key={i}
          >
            <LogosInnerItem image={listItem.logo} link={listItem.link} logoSize={logoSize} />
          </div>
        ))}
      </Marquee>
    );
  } else {
    return (
      <div className={`w-full ${g} grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6`}>
        {logos.map((listItem, i) => (
          <LogosInnerItem key={i} image={listItem.logo} logoSize={logoSize} />
        ))}
      </div>
    );
  }
};

export const LogosInnerItem = ({ image, link, logoSize = 'small' }) => {
  let ls = '';
  if (logoSize === 'large') {
    ls = 'max-w-[110px] max-h-[30px] mx-auto';
  }
  if (logoSize === 'medium') {
    ls = 'max-w-[148px] mx-auto';
  }
  if (logoSize === 'small') {
    ls = 'max-w-[98px] mx-auto';
  }
  return (
    <>
      {link ? (
        <a
          href={link.url}
          target={link.target}
          className={`transition ease-in-out duration-200 hover:opacity-50 w-full h-full py-4 flex items-center justify-center ${
            logoSize === 'small' ? 'max-w-[98px]' : ''
          }`}
        >
          <Image image={image.localFile.publicURL} className={ls} />
        </a>
      ) : (
        <figure className={`mx-auto my-auto ${logoSize === 'small' ? 'max-w-[98px]' : 'max-w-[100px]'}`}>
          <Image image={image.localFile.publicURL} className={ls} />
        </figure>
      )}
    </>
  );
};
