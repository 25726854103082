import React from 'react';
import { Image } from '~/components/elements/Image';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const StatsFlex = ({ background, statSize, heading, image, stats }) => {
  return <>{statsContent(background, stats, statSize, heading, image)}</>;
};

const statsContent = (background, stats, statSize, heading, image) => {
  if (background) {
    return (
      <div className="container-lg">
        <div className={'section-md lg:py-24 rounded-lg relative ' + background}>
          <div className="container">
            {heading && <h2 className="max-w-2xl mx-auto mb-5 text-center text-h3 md:mb-10">{heading}</h2>}
            {stats && <StatsList listItems={stats} statClass={statSize} background={background} />}
            {image && (
              <figure className="w-[68px] md:w-[12%] lg:w-[147px] absolute bottom-[-12%] right-[8%]">
                <Image image={image} />
              </figure>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container relative">
        {heading && <h2 className="max-w-2xl mx-auto mb-10 text-center text-h2">{heading}</h2>}
        {stats && <StatsList listItems={stats} statClass={statSize} />}
        {image && (
          <figure className="absolute w-full max-w-[80px] md:max-w-[147px] -bottom-20 md:bottom-[-180px] right-[2%]">
            <Image image={image} />
          </figure>
        )}
      </div>
    );
  }
};

const StatsList = ({ listItems, statClass, background }) => {
  let sc = '';
  if (statClass === 'large') {
    sc = 'lg:!text-7xl';
  } else {
    sc = 't-62';
  }
  return (
    <InViewAnimator
    className="grid-cols-3 space-y-4 section-stats-list sm:grid sm:space-y-0"
    inner={(controls) => (
      <>
        {listItems.map((listItem, i) => (
          <InViewAnimatorBasicItem key={i} className="py-4 text-center border rounded-md section-stats-list-item md:py-0 sm:px-4 border-periwinkle md:border-0"
            controls={controls}
            delay={i/6}
            duration={1.5}
            >
            <h3 className={'text-h3 md:text-h4 md:!text-4xl text-forest mb-1 ' + sc}>{listItem.stat}</h3>
            <p className={background ? 'md:text-lg' : 'md:text-lg md:max-w-[190px] mx-auto'}>{listItem.caption}</p>
          </InViewAnimatorBasicItem>
        ))}
      </>
      )}
    />
  );
};
