import React, { useState, useEffect } from "react";
import { CareersListings } from "~/components/elements/CareersListings";

export const Careers = ( page ) => {

  const careers = page.careers;

  return(
    <section id="open-positions" className="py-12 text-center md:py-20 bg-pampas lg:text-left">
      <div className="container">
        <h2 className="text-h2">{careers?.heading}</h2>
      </div>
      <CareersListings />
    </section>
  );
}