import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';

export const TwoColumns = ({ columnA, columnB, imageOffset, imageWidth, flipColumns, link }) => {
  let imageClasses = [''],
    imageContainerClasses = ['relative h-full'],
    containerClasses = ['lg:px-12 relative'],
    textInnerContentClasses = [],
    textInnerClasses =[];

  // apply grid to containing element and centre text within grid col
  if (columnB.image) {
    containerClasses.push(
      'py-8 md:py-10 px-6 lg:px-24 grid md:grid-cols-2 gap-8 md:gap-0 lg:gap-x-14 items-center'
    );
    textInnerClasses.push('mx-auto');
  }
  // if image but not offset or no image, apply padding to container
  if ((columnB.image && !imageOffset) || !columnB.image) {
    containerClasses.push('py-8 md:py-10 lg:py-20');
  }

  if (!flipColumns === true) {
    imageContainerClasses.push('md:order-last');
  }
  // max width for image
  if (imageWidth === 'large') {
    imageClasses.push('max-w-[490px]');
    textInnerClasses = ['max-w-[486px]'];
    textInnerContentClasses =['max-w-[405px]'];
  } else {
    imageClasses.push('max-w-[410px]');
    textInnerClasses = ['max-w-[420px]'];
  }
  if (imageOffset) {
    if (imageOffset === 'bottom') {
      imageContainerClasses.push('md:top-[3.4rem] xl:right-[-50px] md:h-[110%] w-[160px] md:w-full mx-auto');
      imageClasses.push('md:absolute w-full h-full object-contain object-center');
    } else {
      imageContainerClasses.push('md:bottom-16 w-[160px] md:w-full mx-auto');
      imageClasses.push('md:absolute -top-6');
    }
  }
  // apply padding to text block if image is offset
  if (columnB.image && imageOffset) {
    textInnerClasses.push('md:px-5 lg:px-0 md:py-10 lg:py-12');
  }
  return (
    <div className={containerClasses.join(' ')}>
      {columnB.image && (
        <figure className={imageContainerClasses.join(' ')}>
          <Image image={columnB.image} className={imageClasses.join(' ')} />
        </figure>
      )}
      <div className="max-w-[486px] text-center md:text-left">
        <div className={textInnerClasses.join(' ')}>
          {columnA.subheading && <p className={'font-bold mb-4 text-fountain'}>{columnA.subheading}</p>}
          <h2 className="mb-4 text-h3">{columnA.heading}</h2>
          <div className={`text-[17px] lg:text-[19px] ${textInnerContentClasses}`} dangerouslySetInnerHTML={{ __html: columnA.content }} />
          {columnA?.link && (
            <Link
            className={'relative z-10 mt-6 py-2 text-lg font-bold transition-all duration-500 rounded-full md:whitespace-nowrap leading-1 hover:px-4  hover:text-deepsea hover:bg-fountain'}
            title={columnA.link.title}
            url={columnA.link.url}
            iconAfter
          />
          )}
        </div>
      </div>
    </div>
  );
};
