import React from 'react';
// import { FourOhFour } from '~/components/landing/FourOhFour';
import { DemoRequest } from '~/components/landing/DemoRequest';
import { Customers } from '~/components/landing/Customers';
import { Legal } from '~/components/landing/Legal';
import { Resources } from '~/components/landing/Resources';
import { FourOhFour } from '~/components/landing/FourOhFour';
import { Careers } from '~/components/landing/Careers';

export const LandingPage = (page) => {
  const { layout } = page;

  if (layout === 'customer') {
    return <Customers {...page} />;
  } else if (layout === 'demo-request') {
    return <DemoRequest {...page} />;
  }  else if (layout === 'resources') {
    return <Resources {...page} />;
  }else if (layout === 'legal') {
    return <Legal {...page} />; 
  }else if (layout === 'fourohfour') {
    return <FourOhFour {...page} />;
  }else if (layout === 'careers') {
    return <Careers {...page} />;
  } else {
    console.log('IMPLEMENT', page);
    return null;
  }
};
