import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button } from '~/components/elements/button/Button';

export const QuoteFeature = ({
  image,
  imageFirst,
  preHeading,
  heading,
  content,
  button,
  quote,
  personImage,
  personName,
  personRole,
}) => {
  return (
    <section className="section">
      <div className={'container md:gap-12 md:flex items-center ' + (!imageFirst && 'md:flex-row-reverse')}>
        {image && (
          <figure className="md:w-1/2 lg:w-3/5 rounded-lg overflow-hidden">
            <Image image={image} />
          </figure>
        )}
        <div className="md:w-1/2 lg:w-2/5">
          <div className="max-w-[756px] mt-6 md:my-0">
            {preHeading && <p className="font-bold text-forest mb-4">{preHeading}</p>}
            <h2 className="text-h2 mb-4">{heading}</h2>
            <div className="text-l prose max-w-sm" dangerouslySetInnerHTML={{ __html: content }} />
            {quote && (
              <div className="mt-8">
                <hr className="border-ash" />
                <figure className="mt-8">{QuoteContent(quote, personImage, personName, personRole)}</figure>
              </div>
            )}
            {button && (
              <Button className="mt-7" link={{ url: button.url, title: button.title }} type="button" color="forest" />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const QuoteContent = (quote, personImage, personName, personRole) => {
  let content = '';
  if (personImage && personName) {
    content = (
      <div className="flex flex-row items-start">
        <div>
          <figure className="w-[42px] h-[42px] mr-6 rounded-md bg-fountain overflow-hidden">
            <Image image={personImage} />
          </figure>
        </div>
        <div className="max-w-xs">
          <blockquote className="font-heading mb-3">&ldquo;{quote}&rdquo;</blockquote>
          <figcaption className="text-mineral text-sm">
            <span className="font-bold">{personName}</span>
            {personRole && <span>, {personRole}</span>}
          </figcaption>
        </div>
      </div>
    );
  }
  if (personName && !personImage) {
    content = (
      <div>
        <blockquote className="font-heading mb-3">{quote}</blockquote>
        <figcaption className="text-mineral">
          <span className="font-bold">{personName}</span>
          {personRole && <span>, {personRole}</span>}
        </figcaption>
      </div>
    );
  }
  return content;
};
