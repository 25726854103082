import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';

export const TwoImageText = ({ preHeading, heading, content, link, imageLeft, imageRight, offsetTop = false }) => {
  return (
    <div className={offsetTop ? 'z-3 md:!pt-0' : 'section'}>
      <div
        className={
          'container bg-pampas rounded-lg section text-center relative ' + (offsetTop ? 'mt-[-100px] md:mt-[-120px] lg:mt-[-215px]' : '')
        }
      >
        <div className="max-w-3xl mx-auto lg:my-10">
          {preHeading && <p className="mb-4 font-bold text-forest">{preHeading}</p>}
          {heading && <h3 className="mb-4 text-h3">{heading}</h3>}
          {heading && <p className="text-[17px] lg:text-[19px] max-w-2xl mx-auto">{content}</p>}
          {link.link && (
            <Link
            className={'relative z-10 mt-6 py-2 text-forest text-lg font-bold transition-all duration-500 rounded-full md:whitespace-nowrap leading-1 hover:px-4  hover:text-pampas hover:bg-forest'}
              title={link.link.title}
              iconAfter
              url={link.link.url}
            />
          )}
        </div>
        {imageLeft && (
          <figure className="hidden md:block lg:flex justify-center items-center w-[14%] lg:h-[200px] lg:w-[200px] absolute top-[-20%] left-[8%]">
            <Image image={imageLeft} className="w-full h-auto max-w-full max-h-full" />
          </figure>
        )}
        {imageRight && (
          <figure className="hidden md:block lg:flex justify-center items-center w-[14%] lg:h-[200px] lg:w-[200px] absolute bottom-[-12%] right-[8%]">
            <Image image={imageRight} className="w-full h-auto max-w-full max-h-full" />
          </figure>
        )}
      </div>
    </div>
  );
};
