import React, { useEffect, useRef, useState } from 'react';
import { Image } from '~/components/elements/Image';
import { AnimatePresence, motion } from 'framer-motion';
import PhoneUnderlay from '~/assets/images/phone/phone-underlay.svg';

export const ScrollSection = ({ heading, scrollItems }) => {
  const refs = [];
  const [active, setActive] = useState(0);

  useEffect(() => {
    refs.reverse();
    const onScroll = (e) => {
      if (typeof window === undefined) return;
      const scrollY = e.target.documentElement.scrollTop;
      for (let i in refs) {
        const ref = refs[i];
        if (!ref.current) break;
        const top = window.pageYOffset + ref.current.getBoundingClientRect().top - 200;
        if (scrollY > top) {
          setActive(refs.length - i - 1);
          break;
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className="container relative md:pb-20">
      {heading && <h2 className="max-w-2xl mx-auto mb-10 text-center text-h2">{heading}</h2>}
      {scrollItems && (
        <div className="items-stretch gap-8 md:gap-x-12 lg:gap-x-18 md:flex">
          <div className="hidden md:w-1/2 lg:w-2/3 md:block">
            <figure className="flex items-start sticky top-[20vh] pb-[60%] md:mb-6 lg:mb-[6.5rem]" style={{ zIndex: 5 }}>
              <div className="absolute top-0 left-0 right-0">
                <div className="relative w-[44%] mx-auto mt-[-28px]">
                  <img className="mx-auto" src={PhoneUnderlay} alt="" />
                  <AnimatePresence exitBeforeEnter>
                    {scrollItems.map((listItem, i) => {
                      return (
                        i === active &&
                        listItem.internalProductImage && (
                          <motion.div
                            key={`front-Image${i}`}
                            initial={{ opacity: 0, y: 0 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 0 }}
                            transition={{ duration: 0.35 }}
                            className="z-3 absolute top-[2.5%] left-[14%] right-[16%]"
                          >
                            <Image image={listItem.internalProductImage} />
                          </motion.div>
                        )
                      );
                    })}
                  </AnimatePresence>
                  <AnimatePresence exitBeforeEnter>
                    {scrollItems.map((listItem, i) => {
                      return (
                        i === active &&
                        listItem.extraImage && (
                          <motion.div
                            key={`front-Image${i}`}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -20 }}
                            transition={{ duration: 0.35, delay: 0.1 }}
                            className={`z-4 absolute ${listItem.extraImageClass}`}
                          >
                            <Image image={listItem.extraImage} />
                          </motion.div>
                        )
                      );
                    })}
                  </AnimatePresence>
                </div>
              </div>
            </figure>
          </div>

          <div className="md:w-1/2 lg:w-1/3">
            {scrollItems.map((listItem, i) => {
              const ref = useRef();
              refs.push(ref);
              return (
                <article
                  ref={ref}
                  key={i}
                  className={`relative ${i !== 0 ? ' md:pt-64 lg:pt-[16rem] lg:pb-[5rem]' : ''} `}
                >
                  <div
                    key={`back-Image${i}`}
                    className="rounded-lg overflow-hidden absolute hidden md:block md:mr-8 lg:mr-16 right-[104%] md:w-[100%] lg:w-[185%]"
                  >
                    <Image image={listItem.backgroundImage} className="object-cover w-full h-full !max-w-none" />
                  </div>
                  <figure className="relative flex items-center md:w-1/2 lg:w-2/3 md:hidden">
                    {listItem.backgroundImage && (
                      <motion.div
                        initial={{ opacity: 0, y: 0 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="rounded-lg overflow-hidden absolute z-1 max-w-[714px] w-full top-[7%] inset-x-0 mx-auto"
                      >
                        <Image image={listItem.backgroundImage} className="object-cover w-full h-full !max-w-none" />
                      </motion.div>
                    )}
                    {listItem.image && (
                      <motion.div
                        initial={{ opacity: 0, y: 0 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        viewport={{ once: true }}
                        className="z-2 relative w-[50%] mx-auto top-[2.5%]"
                      >
                        <Image image={listItem.image} />
                      </motion.div>
                    )}
                  </figure>
                  <div className="flex flex-col justify-between mb-3 text-center md:text-left">
                    {listItem.icon && (
                      <div className="hidden mt-8 md:mt-0 lg:block">
                        <figure className="w-[88px]">
                          <Image image={listItem.icon} />
                        </figure>
                      </div>
                    )}
                    <div className="mt-6 mb-8 lg:mt-32">
                      {/* {listItem.subheading && (
                        <p className="mb-3 font-bold text-forest">{listItem.subheading}</p>
                      )} */}
                      {listItem.heading && <h3 className="text-h4 !font-body !font-bold">{listItem.heading}</h3>}
                      {listItem.content && (
                        <div className="max-w-sm mt-5 text-[16px] md:text-[19px]" dangerouslySetInnerHTML={{ __html: listItem.content }} />
                      )}
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
