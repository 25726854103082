import * as React from 'react';
import { FeaturedCustomer } from '~/components/flexible/FeaturedCustomer';
import { Stats } from '~/components/flexible/Stats';
import { CustomersPostsList } from '~/components/flexible/CustomersPostsList';
import { useCustomerStories } from '~/hooks/useCustomerStories';
import { HeroInner } from '~/components/flexible/Hero';
import { LogosInner } from '~/components/flexible/LogoSlider';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const Customers = (page) => {
  const largeFeatures = page.customers.customerFeaturesLarge;
  const featuredCustomers = page.customers.featuredCustomers;
  const customerStories = useCustomerStories();
  return (
    <>
      <section className="section !pb-0">
        <header className={`pt-8 pb-6 md:py-16 lg:pb-20 max-w-[1300px] mx-auto px-6 md:px-8`}>
          <div>
            <HeroInner
              image={page.customers.mastheadImage}
              imageFirst={true}
              preHeading={page.customers.mastheadSubheading}
              heading={page.customers.mastheadHeading}
              content={page.customers.mastheadcontent}
              maxTextWidth="630"
              maxImageWidth="500"
              imageSize="small"
              noMobPad={true}
              flipColumnsOnMobile={true}
            />
            {page.customers.logoItems && (
              <InViewAnimator
                inner={(controls) => (
                  <>
                    <InViewAnimatorBasicItem
                      className="mt-9 lg:mt-16 max-w-[1030px] mx-auto"
                      controls={controls}
                      delay={0.6}
                      prop="y"
                      start={50}
                    >
                      <LogosInner logos={page.customers.logoItems} />
                    </InViewAnimatorBasicItem>
                  </>
                )}
              />
            )}
          </div>
        </header>
      </section>

      {largeFeatures.map(({ postTypeCustomer, link }, i) => (
        <section className="py-6 md:py-10 lg:py-16" key={i}>
          <FeaturedCustomer
            image={postTypeCustomer.previewImage}
            imageFirst={i! % 2 !== 0 && 'true'}
            logo={postTypeCustomer.previewLogo}
            heading={postTypeCustomer.previewContent}
            link={{ title: 'Read story', url: link }}
            quote={postTypeCustomer.previewQuote}
            personImage={postTypeCustomer.previewProfileImage}
            personName={postTypeCustomer.previewName}
            personRole={postTypeCustomer.previewRole}
          />
        </section>
      ))}

      <Stats
        background="bg-pampas"
        twoColumns={true}
        heading={page.customers.heading}
        content={page.customers.content}
        listItems={page.customers.listItems}
      />

      <CustomersPostsList listItems={featuredCustomers} />

      <CustomersPostsList
        columns="3"
        filters={true}
        headingClasses="text-h5"
        heading="Filter case studies"
        listItems={customerStories}
      />
    </>
  );
};
