import React from 'react';
import { ContentRenderer } from '~/components/layouts/ContentRenderer';

export const Section = ({
  prefix,
  innerSpacing,
  outerSpacingTop,
  outerSpacingBottom,
  backgroundColour,
  previousBackground,
  nextBackground,
  removeTopPadding,
  removeBottomPadding,
  maxWidth,
  classname,
  content = [],
  components = [],
}) => {
  if (!content.length) content = components;

  const classes = ['section'];

  if (previousBackground !== backgroundColour) {
    classes.push('section-top');
  }
  if (nextBackground !== backgroundColour) {
    classes.push('section-bot');
  }

  if (backgroundColour === 'white') {
  } else if (backgroundColour === 'pearl') {
    classes.push('bg-pearl');
  } else if (backgroundColour === 'sea') {
    classes.push('bg-sea text-white');
  } else if (backgroundColour === 'periwinkle') {
    classes.push('bg-periwinkle');
  } else if (backgroundColour === 'iceberg') {
    classes.push('bg-iceberg');
  } else if (backgroundColour === 'glacier') {
    classes.push('bg-glacier');
  } else if (backgroundColour === 'oyster') {
    classes.push('bg-oyster');
  } else if (backgroundColour === 'Fog') {
    classes.push('bg-Fog');
  } else if (backgroundColour === 'pampas') {
    classes.push('bg-pampas');
  } else if (backgroundColour === 'fountain') {
    classes.push('bg-fountain text-pampas');
  } else if (backgroundColour === 'lavender') {
    classes.push('bg-lavender  text-pampas');
  }

  if (maxWidth === '1') {
    classes.push('max-w-[1200px] mx-6 md:mx-8 xl:mx-auto rounded-lg');
  } else if (maxWidth === '2') {
    classes.push('container-full rounded-lg');
  }

  if (innerSpacing === '40') {
    classes.push('space-y-12 md:space-y-16 lg:space-y-24 xl:space-y-40');
  } else if (innerSpacing === '12') {
    classes.push('space-y-8 md:space-y-10 xl:space-y-12');
  } else if (innerSpacing === '2') {
    classes.push('space-y-2');
  } else {
    classes.push('space-y-8 md:space-y-12 xl:space-y-20');
  }

  if (outerSpacingTop === '1') {
    classes.push('mt-8 lg:mt-12');
  } else if (outerSpacingTop === '2') {
    classes.push('mt-12 md:mt-16 lg:mt-24');
  } else if (outerSpacingTop === '3') {
    classes.push('mt-16 md:mt-24 lg:mt-32');
  }

  if (outerSpacingBottom === '1') {
    classes.push('mb-8 lg:mb-12');
  } else if (outerSpacingBottom === '2') {
    classes.push('mb-12 md:mb-16 lg:mb-24');
  } else if (outerSpacingBottom === '3') {
    classes.push('mb-16 md:mb-24 lg:mb-32');
  }

  if (removeTopPadding) {
    classes.push('!pt-0');
  }
  if (removeBottomPadding) {
    classes.push('!pb-0');
  }

  let layouts = 'multiple';
  if (content && content.length === 1) {
    layouts = content[0].fieldGroupName.replace(prefix, '');
  }

  if (classname) {
    classes.push(classname);
  }

  return (
    <section data-layouts={layouts} data-background={backgroundColour} className={classes.join(' ')}>
      <ContentRenderer prefix={prefix} content={content} />
    </section>
  );
};
