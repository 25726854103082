import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MastheadBackgroundImage = ({ backgroundImage, subheading, heading, link, backgroundColour, fade, height }) => {
	let bgc = 'from-[#faf8f4]';
	let textCardClasses = [];
	let extraContainerClasses = [];
	if (backgroundColour) {
		bgc = backgroundColour;
	}
	if (height === 'large'){
		textCardClasses.push(' mb-[200px] md:mb-[460px] lg:mb-[620px]');
		extraContainerClasses.push(' lg:pt-[12rem] lg:pb-[16rem]')
	} else {
		textCardClasses.push(' mb-[120px] md:mb-[260px] lg:mb-[320px]');
	}
	console.log(fade);
	console.log(height);
	return (
		<InViewAnimator className={`pt-20 pb-32 md:py-40 ${extraContainerClasses} section-background-image-hero relative z-1 overflow-hidden`}
		inner={(controls) => (
			<>
			{backgroundImage && (
				<figure className="absolute bottom-0 left-0 w-full h-full overflow-hidden hero-image-wrapper z-1">
					<div className="absolute bottom-0 left-0 w-full h-full">
						<Image image={backgroundImage} className=" object-cover w-full h-[128%] md:h-[100%]" />
						{fade &&
							<div className="absolute w-full h-[20%] lg:h-[10%] bottom-0 mb-[-2px] left-0 bg-gradient-to-t from-pearl z-2"></div>
						}
					</div>
				</figure>
			)}
			<InViewAnimatorBasicItem
				controls={controls}
				delay={0.2}
				prop="y"
				start={50}
				className={`container relative z-4 text-center section-dashboard-hero--text !max-w-[922px] ${textCardClasses}`}>
				{subheading && <p className="font-bold text-forest mb-5 lg:text-[19px]">{subheading}</p>}
				<h1 className="text-h3">{heading}</h1>
				{link.link && (
					<Button
						className="mt-6 md:mt-14"
						link={{ url: link.link.url, title: link.link.title, target: link.link.target }}
						type={link.type}
						color={ButtonColor.Forest}
					/>
				)}
			</InViewAnimatorBasicItem>
			</>
		)}
		/>
	);
};
