import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button, ButtonType, ButtonColor } from '~/components/elements/button/Button';
import { Icon, IconType } from '~/components/elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const CopyStats = ({ subheading, heading, content,  links, image, listItems, offsetTop }) => {
	return (
		<div className={offsetTop ? 'relative z-3 md:!pt-0' : 'section'}>
			<div className={'container-lg md:flex gap-8 ' + (offsetTop ? 'md:mt-[-50px]' : '')}>
				<div className="md:w-1/2 lg:w-2/3 md:bg-pampas md:rounded-lg md:p-8 lg:p-16">
					<div className="lg:flex">
						<div className="lg:w-[62%] text-center md:text-left">
							{subheading && <p className="mb-4 font-bold text-forest">{subheading}</p>}
							{heading && <h3 className="mb-4 text-h3">{heading}</h3>}
							{content && <div className="prose" dangerouslySetInnerHTML={{ __html: content }} />}
							{links && (
								<div className="items-center lg:flex mt-7 lg:mt-14">
									{links.map(({ link, type }, i) =>
										<a
											href={link.url}
											className={`${type === 'button' ? 'font-bold my-4 py-2 px-4 bg-forest text-white rounded-full mr-6 inline-flex items-center' : 'font-bold my-4 flex justify-center md:justify-start items-center' }`}
											key={i}
										>
											<span>{link.title}</span>
											<i className="block fw-icon ml-2 w-[14px] h-[14px]">
												<Icon type={IconType.ChevronRight} />
											</i>
										</a>
									)}
								</div>
							)}
						</div>
						<figure className="w-[38%] hidden lg:flex items-end">
							<Image image={image} className="relative top-[130px] left-8 w-[110%] max-w-none" />
						</figure>
					</div>
				</div>
				{listItems && (
					<InViewAnimator className="flex-col justify-center mt-12 space-y-4 text-center md:w-1/2 lg:w-1/3 md:flex md:text-left md:mt-0 md:bg-pampas md:rounded-lg md:p-8 lg:p-16 md:space-y-8 lg:space-y-14"
					inner={(controls) => (
						<>
							{listItems.map((listItem, i) => (
								<InViewAnimatorBasicItem key={i}
									controls={controls}
									delay={i/6}
									duration={1.5}
									prop="x"
									start={50}
									className="px-4 py-4 border rounded-md border-periwinkle md:border-0 lg:px-0 lg:py-0"
								>
									<h3 className="mb-2 lg0my-6 text-h2 md:text-h3 text-forest md:my-0">{listItem.stat}</h3>
									<p className="max-w-[220px] mx-auto md:mx-0 md:max-w-[220px]">{listItem.description}</p>
								</InViewAnimatorBasicItem>
							))}
						</>
					)}
					/>
				)}
			</div>
		</div>
	);
};
