import React from 'react';
import { Link } from '~/components/elements/Link';
import { Image } from '~/components/elements/Image';
import { Icon, IconType } from '~/components/elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const EmployeeList = ({ employeeItems, heading }) => {
  return (
      <div className="text-center container-lg">
        {heading && <h2 className="text-h3 mb-12 lg:mb-24 mx-auto max-w-[926px]">{heading}</h2>}
        {employeeItems && (
          <InViewAnimator className="grid grid-cols-12 gap-y-8 md:gap-6"
          inner={(controls) => (
            <>
            {employeeItems.map((listItem, i) => (
              <EmployeeItem key={i} {...listItem} controls={controls} time={i} />
            ))}
            </>
          )}
          />
        )}
      </div>
  );
};

export const EmployeeItem = ({ name, role, image, quote, smallText, controls, time }) => {
  return (
    <InViewAnimatorBasicItem className="col-span-12 group md:col-span-6 lg:col-span-4"
      controls={controls}
      delay={time/6}
      duration={1.5}
    >
      {image && (
        <figure className="relative w-full overflow-hidden rounded-md employee-item safari-overflow">
          <div className="overflow-hidden rounded-md">
          <Image image={image} />
          </div>
          <div className="top-0 left-0 flex w-full h-full py-4 md:absolute md:p-4 xl:p-8">
            <div className="md:opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-[500ms] border border-patina border-opacity-10 lg:bg-white md:border-none rounded-md flex flex-col justify-center xl:justify-between p-4 lg:px-6 md:py-10 lg:py-[20%] xl:py-[27%]">
              {quote && <blockquote className="font-heading employee-item-quote">{quote}</blockquote>}
              {smallText && <p className="text-sm text-mineral max-w-[240px] mx-auto mt-4">{smallText}</p>}
            </div>
          </div>
        </figure>
      )}
      <div className="max-w-[296px] mx-auto flex justify-center items-center flex-row border-t border-b border-periwinkle divide-x divide-periwinkle md:mt-8">
        <h4 className="px-4 py-1 text-lg lg:px-6 text-forest">{name}</h4>
        <p className="px-4 py-3 text-patina lg:px-6">{role}</p>
      </div>
    </InViewAnimatorBasicItem>
  );
};
