import React from 'react';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';
import { NewsletterForm } from '~/components/elements/forms/NewsletterForm';

export const NewsletterSignup = ({ heading }) => {
  return (
    <section className="section">
      <div className="container items-center gap-10 text-white rounded-lg lg:px-16 bg-deepsea section-sm md:flex">
        {heading && (
          <h3 className="my-2 text-h4 md:w-1/2">
            <span className="block md:max-w-sm">{heading}</span>
          </h3>
        )}
        <div className="my-2 md:w-1/2">
         <NewsletterForm buttonColour='zest'/>
        </div>
      </div>
    </section>
  );
};
