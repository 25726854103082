import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button } from '~/components/elements/button/Button';
import { Link } from '~/components/elements/Link';
import { Icon, IconType } from '~/components/elements/Icon';

export const TwoColumnExtraContent = ({ flipColumns, imageFullBleed, contentMaxWidth, columnA, columnB }) => {
  let containerClasses = ['md:gap-6 lg:gap-12 md:flex items-center'];
  if (!flipColumns) {
    containerClasses.push('md:flex-row-reverse');
  }
  if (imageFullBleed) {
    containerClasses.push('bg-pampas rounded-lg overflow-hidden md:items-stretch safari-overflow');
  }

  let contentWidth =[];
  let imageWidth =[];
  if (contentMaxWidth === 'large') {
    contentWidth.push('max-w-[462px]');
    imageWidth.push('max-w-[615px]');
  } else if (contentMaxWidth === 'small'){
    contentWidth.push('max-w-[410px]');
    imageWidth.push('max-w-[615px]');
  } else {
    contentWidth.push('max-w-[442px]');
    imageWidth.push('max-w-[700px]');
  }
  const listItems = columnA.listGroup;
  const testimonialQuote = columnA.testimonial?.postTypeTestimonial;
  return (
    <header className="container">
      <div className={containerClasses.join(' ')}>
        {columnB.image &&
        <FeatureImage image={columnB.image} imageFullBleed={imageFullBleed} extraClasses={imageWidth} />
        }
        <div className={'md:w-1/2 md:flex items-center justify-center' + (listItems ? '' : 'text-center md:text-left')  + ' mt-0 md:mt-0 ' + (imageFullBleed ? 'py-8 md:p-8 lg:p-12 !pl-0' : '')}>
          <div className={contentWidth +' ' + (imageFullBleed && ' container')}>
            {columnA.subheading && <p className="mb-4 font-bold text-forest">{columnA.subheading}</p>}
            <h2 className="mb-4 t-48">{columnA.heading}</h2>
            <div className="text-[17px] md:text-[19px] max-w-[400px]" dangerouslySetInnerHTML={{ __html: columnA.content }} />
            {columnA.listHeading && <p className="mt-10 mb-4 font-bold">{columnA.listHeading}</p>}
            {listItems && (
              <ul className={'max-w-sm' + (!columnA.listHeading && ' mt-8')}>
                {listItems.map((Item, i) => (
                  <li key={i} className="flex items-start mt-3 mb-3">
                    <i className="shrink-0 block fw-icon mr-2 mt-[5px] w-[18px] h-[18px] text-forest">
                      <Icon type={IconType.CircleTick} />
                    </i>
                    <div className="w-full">
                      <p className="text-lg lg:w-[120%] md:leading-6">{Item.listObject}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {testimonialQuote && (
              <>
                <div className="mt-8">
                  <hr className="border-ash" />
                  <figure className="mt-8">{QuoteContent(testimonialQuote)}</figure>
                </div>
              </>
            )}
            {columnA.link?.link && (
              <>
                {columnA.link.type === 'button' ? (
                  <Button
                    className="mt-7"
                    link={{ url: columnA.link.link.url, title: columnA.link.link.title }}
                    type="button"
                    color="forest"
                  />
                ) : (
                  <Link
                    className="mt-6 font-bold text-forest"
                    title={columnA.link.link.title}
                    iconAfter
                    url={columnA.link.link.url}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export const FeatureImage = ({ image, imageFullBleed, extraClasses }) => {
  if (imageFullBleed) {
    return (
      <figure className={`md:w-1/2 ` + extraClasses}>
        <Image image={image} className="h-full md:object-cover" />
      </figure>
    );
  } else {
    return (
      <figure className={`w-full `+ extraClasses}>
        <Image image={image} />
      </figure>
    );
  }
};

export const QuoteContent = (testimonialQuote) => {
  let content = '';
  if (testimonialQuote.image.localFile.publicURL) {
    content = (
      <div className="flex flex-row items-start">
        <div>
          <figure className={`w-[42px] h-[42px] mr-6 rounded-md overflow-hidden bg-${testimonialQuote.imageBackground}`}>
            <Image image={testimonialQuote.image.localFile.publicURL} />
          </figure>
        </div>
        <div className="max-w-xs">
          <blockquote className="mb-3 font-heading">&ldquo;{testimonialQuote.content}&rdquo;</blockquote>
          <figcaption className="text-sm text-mineral">
            <span className="font-bold">{testimonialQuote.name}</span>
            {testimonialQuote.jobTitle && <span>, {testimonialQuote.jobTitle}</span>}
          </figcaption>
        </div>
      </div>
    );
  }
  if (!testimonialQuote.image.localFile.publicURL) {
    content = (
      <div>
        <blockquote className="mb-3 font-heading">{testimonialQuote.content}</blockquote>
        <figcaption className="text-mineral">
          <span className="font-bold">{testimonialQuote.name}</span>
          {testimonialQuote.jobTitle && <span>, {testimonialQuote.jobTitle}</span>}
        </figcaption>
      </div>
    );
  }
  return content;
};
