import React from "react";
import Image from '~/components/elements/Image';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';

export const FourOhFour = ( page ) =>{

  const fourOhFour = page.fourOhFour;
  return(
    <section className="mt-24 mb-20 md:my-32 lg:my-40 overflow-hidden">
      <div className="container grid grid-cols-12 items-center">
        <div className="col-span-12 md:col-span-7 lg:col-span-5">
          <div className="sm:px-[10%] lg:px-0 md:max-w-[714px] mx-auto">
            {fourOhFour.subheading && <p className="font-bold text-forest mb-5 lg:text-[19px]">{fourOhFour.subheading}</p>}
            <h1 className="text-h1" dangerouslySetInnerHTML={{ __html: fourOhFour.heading}}/>
            {fourOhFour.content && <div className="md:text-lg mt-6 lg:text-xl" dangerouslySetInnerHTML={{ __html: fourOhFour.content }} />}
            {fourOhFour.link &&
              <Button
                className="mt-10"
                link={{ url: fourOhFour.link.url, title: fourOhFour.link.title, target: fourOhFour.link.target }}
                type='button'
                color={ButtonColor.Forest}
              />
            }
          </div>
        </div>
        <div className="col-span-12 md:col-span-5 lg:col-span-7 relative">
          <figure className="z-1 bottom-0 left-0 w-full h-full">
            <Image image={fourOhFour.image} />
					</figure>
        </div>
      </div>
    </section>
  )
}