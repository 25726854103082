import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { PrimaryTextColour } from '~/components/utility/TextColour';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const CardRow = ({ cardItems }) => {
  return <div className="container">{cardItems && <CardLinksList listItems={cardItems} />}</div>;
};

export const CardLinksList = ({ listItems, }) => (
  <InViewAnimator className="grid gap-y-6 md:grid-cols-3 md:gap-x-6"
    inner={(controls) => (
      <>
      {listItems.map((listItem, i) => (
        <CardLinksListItem key={i} {...listItem} time={i} controls={controls} />
      ))}
      </>
    )}
  />
);

export const CardLinksListItem = ({ cardBackground, heading, content, image, imageAlign, link, time, controls }) => (
  <InViewAnimatorBasicItem className={PrimaryTextColour(cardBackground) + ' flex flex-col relative rounded-lg ' + cardBackground}
    controls={controls}
    delay={time/6}
    duration={1.5}
  >
    <a href={link.url} title={link.title} className="group duration-500 flex flex-1 flex-col justify-between p-6 lg:p-[2.3rem] w-full">
      <div className={(imageAlign === 'bottom' ? 'mb-[20%] lg:mb-[57%]' : '') + ' relative z-10'}>
        <h3 className={(imageAlign === 'bottom' ? '' : '') + ' text-h4 mb-5'}>{heading}</h3>
        {content && <div className="max-w-sm text-[17px] lg:text-[18px] transition-all duration-200 ease-in-out lg:translate-y-4 group-hover:translate-y-0 lg:opacity-0 group-hover:opacity-100" dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
      <div>
        {image && (
          <figure
            className={
              imageAlign === 'bottom'
                ? 'pointer-events-none absolute bottom-8 lg:bottom-10 mr-6 md:mr-0 right-0 w-[150px] xs:w-[55%] md:w-[65%] lg:w-[80%] z-5'
                : ' z-5 lg:w-full w-[150px] lg:max-w-[300px] lg:h-[205px] flex justify-end items-center ml-auto lg:mx-auto'
            }
          >
            <Image image={image} className={imageAlign === 'bottom' ? '' : 'max-w-full max-h-full w-full'} />
          </figure>
        )}
        {link && (
          <p>
            <Link
              faux
              className={'relative z-10 py-2 text-lg font-bold transition-all duration-500 rounded-full md:whitespace-nowrap leading-1 group-hover:px-4  group-hover:text-deepsea ' + (imageAlign === 'bottom' ? 'group-hover:bg-white' : 'group-hover:bg-fountain')}
              title={link.title}
              url={link.url}
              iconAfter
            />
          </p>
        )}
      </div>
    </a>
  </InViewAnimatorBasicItem>
);
