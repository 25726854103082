import React from 'react';
import { Button } from '~/components/elements/button/Button';
import { Image } from '~/components/elements/Image';
import { Icon, IconType } from '~/components/elements/Icon';

export const TickListIconColumns = ({ tickListItems, heading, columnItems }) => {
	return (
			<div className="container xl:max-w-[1328px] bg-pampas rounded-lg py-4 px-8 md:py-8 lg:p-16">
				<div className="md:grid grid-cols-4 gap-8">
					{heading && (
						<h2 className="my-4 md:col-span-2 lg:col-span-1 font-body font-bold text-l md:text-lg lg:text-[24px]">
							{heading}
						</h2>
					)}
					{tickListItems && (
						<ul className="my-4 md:col-span-2 lg:col-span-3 grid lg:grid-cols-2 gap-x-8 gap-y-4">
							{tickListItems.map((tickListItem, i) => (
								<TickList key={i} {...tickListItem} />
							))}
						</ul>
					)}
				</div>
				{columnItems && (
					<ul className="my-4 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-[1008px] mx-auto mt-14 lg:mt-20">
						{columnItems.map((twoColListItem, i) => (
							<TwoColCopyList key={i} {...twoColListItem} />
						))}
					</ul>
				)}
			</div>
	);
};

export const TickList = ({ content }) => (
	<li className="flex gap-x-3">
		<i className="block fw-icon text-fountain mt-[2.5px] w-[16px] h-[16px] ">
			<Icon type={IconType.Tick} />
		</i>
		<p>{content}</p>
	</li>
);

export const TwoColCopyList = ({ image, heading, content, link }) => (
	<li className="text-center sm:text-left sm:flex gap-3 sm:gap-8 lg:gap-10">
		{image && (
			<div>
				<figure className="w-[90px] mx-auto sm:mx-0 mb-6 sm:mb-0">
					<Image image={image} />
				</figure>
			</div>
		)}
		<div className='flex flex-col sm:block items-center'>
			<h3 className="text-h5 mb-3">{heading}</h3>
			<p className="text-lg">{content}</p>
			{link.link && 
				<a
					href={link.link.url}
					className={`${link.type === 'button' ? 'font-bold my-4 py-2 px-4 bg-forest text-white rounded-full mr-6 inline-flex items-center' : 'font-bold my-4 flex items-center text-forest' }`}
				>
					<span>{link.link.title}</span>
					<i className="block fw-icon ml-2 w-[14px] h-[14px]">
						<Icon type={IconType.ChevronRight} />
					</i>
				</a>
			 }
		</div>
	</li>
);
