import React from 'react';
import { TextContent } from '~/components/flexible/TextContent';
import { MastheadList } from '~/components/flexible/MastheadList';
import { MastheadHome } from '~/components/flexible/MastheadHome';
import { MastheadLogos } from '~/components/flexible/MastheadLogos';
import { StatsFlex } from '~/components/flexible/StatsFlex';
import { MastheadBackgroundImage } from '~/components/flexible/MastheadBackgroundImage';
import { CopyStats } from '~/components/flexible/CopyStats';
import { TwoColumns } from '~/components/flexible/TwoColumns';
import { TwoColumnExtraContent } from '~/components/flexible/TwoColumnExtraContent';
import { Team } from '~/components/flexible/Team';
import { LogoSlider } from '~/components/flexible/LogoSlider';
import { CardRow } from '~/components/flexible/CardRow';
import { CardStack } from '~/components/flexible/CardStack';
import { IconGrid } from '~/components/flexible/IconGrid';
import { ScrollSection } from '~/components/flexible/ScrollSection';
import { Testimonial } from '~/components/flexible/Testimonial';
import { ContactLanding } from '~/components/flexible/ContactLanding';
import { ComparisonTable } from '~/components/flexible/ComparisonTable';
import { FeatureGrid } from '~/components/flexible/FeatureGrid';
import { TickListIconColumns } from '~/components/flexible/TickListIconColumns';
import { TwoImageText } from '~/components/flexible/TwoImageText';
import { EmployeeList } from '~/components/flexible/EmployeeList';
import { ImageRows } from '~/components/flexible/ImageRows';
import { GridImageText } from '~/components/flexible/GridImageText';
import { Accordion } from '~/components/flexible/Accordion';
import { MediaAsset } from '~/components/flexible/MediaAsset';
import { FeaturedIconGrid } from '~/components/flexible/FeaturedIconGrid';

export const ContentRenderer = ({ prefix, content = [] }) => {
  return (
    <>
      {content.map((layout, i) => {
        const layoutName = layout.fieldGroupName.replace(prefix, '');
        if (layoutName === 'MastheadHome') {
          return <MastheadHome key={i} {...layout} />;
        } else if (layoutName === 'MastheadList') {
          return <MastheadList key={i} {...layout} />;
        } else if (layoutName === 'MastheadLogos') {
          return <MastheadLogos key={i} {...layout} />;
        } else if (layoutName === 'MastheadBackgroundImage') {
          return <MastheadBackgroundImage key={i} {...layout} />;
        } else if (layoutName === 'CopyStats') {
          return <CopyStats key={i} {...layout} />;
        } else if (layoutName === 'Stats') {
          return <StatsFlex key={i} {...layout} />;
        } else if (layoutName === 'TextContent') {
          return <TextContent key={i} {...layout} />;
        } else if (layoutName === 'TwoColumnExtraContent') {
          return <TwoColumnExtraContent key={i} {...layout} />;
        } else if (layoutName === 'Team') {
          return <Team key={i} {...layout} />;
        } else if (layoutName === 'LogoSlider') {
          return <LogoSlider key={i} {...layout} />;
        } else if (layoutName === 'CardRow') {
          return <CardRow key={i} {...layout} />;
        } else if (layoutName === 'CardStack') {
          return <CardStack key={i} {...layout} />;
        } else if (layoutName === 'TwoColumns') {
          return <TwoColumns key={i} {...layout} />;
        } else if (layoutName === 'IconGrid') {
          return <IconGrid key={i} {...layout} />;
        } else if (layoutName === 'ScrollSection') {
          return <ScrollSection key={i} {...layout} />;
        } else if (layoutName === 'Testimonial') {
          return <Testimonial key={i} {...layout} />;
        } else if (layoutName === 'FeatureGrid') {
          return <FeatureGrid key={i} {...layout} />;
        } else if (layoutName === 'ContactLanding') {
          return <ContactLanding key={i} {...layout} />;
        } else if (layoutName === 'ComparisonTable') {
          return <ComparisonTable key={i} {...layout} />;
        } else if (layoutName === 'TickListIconColumns') {
          return <TickListIconColumns key={i} {...layout} />;
        } else if (layoutName === 'TwoImageText') {
          return <TwoImageText key={i} {...layout} />;
        } else if (layoutName === 'EmployeeList') {
          return <EmployeeList key={i} {...layout} />;
        } else if (layoutName === 'ImageRows') {
          return <ImageRows key={i} {...layout} />;
        } else if (layoutName === 'GridImageText') {
          return <GridImageText key={i} {...layout} />;
        } else if (layoutName === 'Accordion') {
          return <Accordion key={i} {...layout} />;
        } else if (layoutName === 'MediaAsset') {
          return <MediaAsset key={i} {...layout} />;
        } else if (layoutName === 'FeaturedIconGrid') {
          return <FeaturedIconGrid key={i} {...layout} />; 
        } else {
          console.log('IMPLEMENT ', layoutName, layout);
        }
        return null;
      })}
    </>
  );
};
