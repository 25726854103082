import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { PrimaryTextColour, SecondaryTextColour } from '~/components/utility/TextColour';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const FeatureGrid = ({ bigFeature, smallFeatures }) => {
  return (
      <InViewAnimator className="container"
        inner={(controls) => (
          <>
            <SmallFeatures listItems={smallFeatures} controls={controls} />
            <BigFeature {...bigFeature} controls={controls} />
          </>
        )}
      />
  );
};

export const SmallFeatures = ({ listItems, controls }) => (
  <ul className="grid-cols-2 gap-6 md:grid">
    {listItems.map((listItem, i) => (
      <li key={i}>
        {listItem.link ? (
        <a
          className={
            'bg-white mb-6 md:mb-0 rounded-lg py-10 lg:py-14 px-6 lg:px-16 text-center block card-hover' +
            (i === 2 ? 'col-span-3 relative md:text-left' : '')
          }
          href={listItem.link.url}
          target={listItem.link.target}
        >
            {listItem.image && (
              <InViewAnimatorBasicItem controls={controls} delay={0.3} prop="y" start={50}
                className={
                  'mb-6 mx-auto ' +
                  (i === 2 ? 'md:absolute top-4 right-[10%] lg:right-[22%] max-w-[226px]' : 'max-w-[100px] lg:max-w-[180px]')
                }
              >
                <Image image={listItem.image} />
              </InViewAnimatorBasicItem>
            )}
            <div className={'max-w-sm mx-auto ' + (i === 2 ? 'md:mx-0' : '')}>
              <h3 className="mb-4 text-h5">{listItem.heading}</h3>
              {listItem.content && <div className="text-[17px] lg:text-[19px]" dangerouslySetInnerHTML={{ __html: listItem.content }} />}
            </div>
        </a>
        ) : (
          <div
            className={
              'bg-white mb-6 md:mb-0 rounded-lg py-6 lg:py-14 px-6 lg:px-16 text-center block ' +
              (i === 2 ? 'col-span-3 relative md:text-left' : '')
            }
          >
              {listItem.image && (
                <InViewAnimatorBasicItem controls={controls} delay={0.3} prop="y" start={50}
                  className={
                    'mb-6 mx-auto ' +
                    (i === 2 ? 'md:absolute top-4 right-[10%] lg:right-[22%] max-w-[226px]' : 'max-w-[180px]')
                  }
                >
                  <Image image={listItem.image} />
                </InViewAnimatorBasicItem>
              )}
              <div className={'max-w-sm mx-auto ' + (i === 2 ? 'md:mx-0' : '')}>
                <h3 className="mb-4 text-h5">{listItem.heading}</h3>
                {listItem.content && <div className="text-[19px]" dangerouslySetInnerHTML={{ __html: listItem.content }} />}
              </div>
          </div>
        )}
      </li>
    ))}
  </ul>
);
export const BigFeature = ({
  heading,
  content,
  imageOffset = 'bottom',
  imageWidth,
  image,
  imageFirst = false,
  link,
  controls,
}) => {

  let imageClasses = [''],
    imageContainerClasses = ['relative h-full px-[20%] sm:px-[25%] md: px-unset md:max-w-[226px]'],
    containerClasses = ['container px-12 lg:px-20 relative py-10 md:py-0'],
    textInnerClasses = ['max-w-[412px]'];
  // apply grid to containing element and centre text within grid col
  if (image) {
    containerClasses.push('grid md:grid-cols-2 gap-8 md:gap-x-14 lg:gap-x-32 items-center');
    textInnerClasses.push('mx-auto');
  }
  // if image but not offset or no image, apply padding to container
  if ((image && !imageOffset) || !image) {
    containerClasses.push('py-8 md:py-10 lg:py-12');
  }
  if (imageFirst === false) {
    imageContainerClasses.push('md:order-last');
  }
  // max width for image
  if (imageWidth) {
    imageClasses.push(imageWidth);
  }
  if (imageOffset) {
    if (imageOffset === 'bottom') {
      imageContainerClasses.push('lg:-mt-6 lg:-mb-2');
      imageClasses.push(' lg:-bottom-6 max-w-[100px] md:max-w-none mx-auto md:mr-0');
    } else {
      imageContainerClasses.push('md:bottom-16');
      imageClasses.push('md:absolute -top-6');
    }
  }
  // apply padding to text block if image is offset
  if (image && imageOffset) {
    textInnerClasses.push('px-2 md:px-0  md:py-10 lg:py-[3.2rem]');
  }

  return (
     <>
    {link ? (
      <a href={link.url} target={link.target} className="block mt-6 bg-white rounded-lg card-hover">
        <div className={containerClasses.join(' ')}>
        {image && (
            <InViewAnimatorBasicItem controls={controls} delay={0.5} prop="y" start={50} className={imageContainerClasses.join(' ') + ` px-2 md:px-0 `}>
              <Image image={image} className={imageClasses.join(' ')} />
            </InViewAnimatorBasicItem>
          )}
          <div>
            <div className={textInnerClasses.join(' ') + ` text-center md:text-left`}>
              <h2 className="mb-4 text-h5">{heading}</h2>
              <div className="text-[17px] lg:text-[19px]" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </a>
      ): (
        <div className="block mt-6 bg-white rounded-lg">
          <div className={containerClasses.join(' ')}>
          {image && (
              <InViewAnimatorBasicItem controls={controls} delay={0.5} prop="y" start={50} className={imageContainerClasses.join(' ')}>
                <Image image={image} className={imageClasses.join(' ')} />
              </InViewAnimatorBasicItem>
            )}
            <div>
              <div className={textInnerClasses.join(' ')}>
                <h2 className="mb-4 text-h5">{heading}</h2>
                <div className="text-[17px] lg:text-[19px]" dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
