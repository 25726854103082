import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button } from '~/components/elements/button/Button';
import { HeroInner } from '~/components/flexible/Hero';
import { LogosInner } from '~/components/flexible/LogoSlider';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MastheadLogos = ({
  mastheadImage,
  flipColumns,
  subheading,
  heading,
  content,
  link,
  logoItems,
  smallerImage,
  reduceSpacing,
}) => {
  let bgClasses = '';
  let rowOrder = 'md:flex-row-reverse';
  if (flipColumns === true) {
    rowOrder = '';
  }
  let largeCol = 'md:w-1/2 xl:w-3/5',
    smallCol = 'md:w-1/2 xl:w-2/5',
    textCol,
    imageCol,
    imageSize;
  if (smallerImage) {
    imageCol = smallCol;
    textCol = largeCol;
    imageSize = 'small';
  } else {
    imageCol = largeCol;
    textCol = smallCol;
  }
  return (
    <header className={`pt-8 pb-6 md:py-16 lg:pb-20`}>
      <div className="md:px-10 lg:px-16">
        <HeroInner
          image={mastheadImage}
          imageFirst={flipColumns}
          preHeading={subheading}
          heading={heading}
          content={content}
          button={link.link}
          // maxTextWidth={maxTextWidth}
          // maxImageWidth={maxImageWidth}
          smallerImage={smallerImage}
          imageSize={imageSize}
          noMobPad={reduceSpacing}
        />
        {/* {logoItems && (
          <InViewAnimator
            inner={(controls) => (
            <>
            <InViewAnimatorBasicItem className="mt-9 lg:mt-16 max-w-[1100px] mx-auto"
              controls={controls}
              delay={0.6}
              prop="y"
              start={50}
            >
              <LogosInner logos={logoItems} />
            </InViewAnimatorBasicItem>
            </>
            )}
          />
        )} */}
      </div>
    </header>
  );
};
