import React from 'react';
import { Image } from '~/components/elements/Image';
import { Button } from '~/components/elements/button/Button';
import { Icon, IconType } from '~/components/elements/Icon';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MastheadList = ({ mastheadImage, subheading, heading, content, link, listHeading, listItems }) => {
  return (
      <InViewAnimator className="container flex flex-col mt-20 mb-12 md:mt-28 md:mb-32 lg:mb-40 md:relative"
        inner={(controls) => (
          <>
          {mastheadImage && (
            <InViewAnimatorBasicItem className="z-1 md:absolute md:w-[64%] h-full bottom-0 right-0"
              controls={controls}
              delay={0.65}
              prop="x"
              start={50}
            >
              <Image image={mastheadImage} className="hidden md:block md:!absolute bottom-0 right-0 w-full" />
            </InViewAnimatorBasicItem>
          )}
          <div>
            <div className="mt-5 md:max-w-[756px]">
              <InViewAnimatorBasicItem className="relative z-4"
                controls={controls}
                delay={0.2}
                prop="y"
                start={50}
              >
              {subheading && <p className="mb-4 font-bold text-forest">{subheading}</p>}
              <h1 className="text-h1">{heading}</h1>
              {content && (
                <div className="text-[19px] max-w-md mt-[26px]" dangerouslySetInnerHTML={{ __html: content }} />
              )}
              </InViewAnimatorBasicItem>
              {mastheadImage && (
            <InViewAnimatorBasicItem className="mt-8 md:hidden"
              controls={controls}
              delay={0.65}
              prop="y"
              start={50}
            >
              <Image image={mastheadImage} className="" />
            </InViewAnimatorBasicItem>
          )}
              <InViewAnimatorBasicItem className="relative z-4"
                controls={controls}
                delay={0.45}
                prop="y"
                start={50}
              >
                {listHeading && <p className="font-bold mt-[40px] lg:mt-[55px] mb-5 text-lg">{listHeading}</p>}
                {listItems && (
                  <ul className="max-w-sm space-y-2 text-lg md:space-y-4">
                    {listItems.map((listItem, i) => (
                      <li key={i} className="flex items-start">
                        <i className="block w-[18px] h-[18px] fw-icon mr-2 mt-[5px] text-forest">
                          <Icon type={IconType.CircleTick} />
                        </i>
                        <p>{listItem.listItem}</p>
                      </li>
                    ))}
                  </ul>
                )}
                {link && (
                  <Button
                    className="mt-5 md:mt-11"
                    link={{ url: link.link.url, title: link.link.title }}
                    type="button"
                    color="forest"
                  />
                )}
              </InViewAnimatorBasicItem>
            </div>
          </div>
          </>
        )}
      />
  );
};
