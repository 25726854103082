import React, { useState } from "react";
import { Image } from '~/components/elements/Image';
import axios from "axios";
import { Icon, IconType } from '~/components/elements/Icon';
import { Button } from '~/components/elements/button/Button';

export const DemoRequestForm = ({ image }) => {

  const [ formSent, setFormStatus ] = useState('initial')

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/27f2abda-8b4a-43ff-9148-5f18f88b8fcf",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
        console.log('success');
        setFormStatus('success');
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
        console.log('fail');
        setFormStatus('fail');
      });
  };

  return (
    <form className="text-left"
      onSubmit={handleOnSubmit}>
      <div className="gap-5 md:flex">
        <fieldset className="my-2 md:w-1/2">
          <label className="my-2 font-regular">First name *</label>
          <input
            className="w-full px-4 md:px-6 py-3 rounded-md flex-1 my-2 leading-4 bg-[#F9F9F7] border-[#F9F9F7] focus:border-forest border transition-all duration-200"
            type="text"
            required
            name="First Name"
          />
        </fieldset>
        <fieldset className="my-2 md:w-1/2">
          <label className="my-2 font-regular">Last name *</label>
          <input
            className="w-full px-4 md:px-6 py-3 rounded-md flex-1 my-2 leading-4 bg-[#F9F9F7] border-[#F9F9F7] focus:border-forest border transition-all duration-200"
            type="text"
            required
            name="Last Name"
          />
        </fieldset>
      </div>
      <div className="gap-5 md:flex">
        <fieldset className="my-2 md:w-1/2">
          <label className="my-2 font-regular">Email *</label>
          <input
            className="w-full px-4 md:px-6 py-3 rounded-md flex-1 my-2 leading-4 bg-[#F9F9F7] border-[#F9F9F7] focus:border-forest border transition-all duration-200"
            type="email"
            required
            name="Email"
          />
        </fieldset>
        <fieldset className="my-2 md:w-1/2">
          <label className="my-2 font-regular">Phone</label>
          <input
            className="w-full px-4 md:px-6 py-3 rounded-md flex-1 my-2 leading-4 bg-[#F9F9F7] border-[#F9F9F7] focus:border-forest border transition-all duration-200"
            type="text"
            name="Phone"
          />
        </fieldset>
      </div>
      <fieldset className="w-full mt-2 mb-4">
        <label className="my-2font-regular">How did you hear about us?</label>
        <div className="relative">
          <input
            className="w-full px-4 md:px-6 py-3 rounded-md flex-1 my-2 leading-4 bg-[#F9F9F7] border-[#F9F9F7] focus:border-forest border transition-all duration-200"
            type="text"
            name="How did you here about us"
          />
        </div>
      </fieldset>
      <fieldset className="w-full mt-2 mb-4">
        <label className="my-2 font-regular">How can we help with?</label>
        <textarea name="Message" className="w-full h-[86px] px-4 md:px-6 py-3 rounded-md flex-1 my-2 leading-4 bg-[#F9F9F7] border-[#F9F9F7] focus:border-forest border transition-all duration-200"></textarea>
      </fieldset>
      <fieldset className="flex items-start w-full gap-3 mt-5 mb-8">
        <input type="checkbox" name='privacy' className="relative top-1" id="privacy" required />
        <label className="text-sm" htmlFor="Privacy">
          I accept Haven’s <a className="underline text-forest hover:text-sea transition-all duration-200" href='/privacy-policy/'>Privacy Policy</a> and agree to receive promotional messages from Haven about its
          products and services. You can unsubscribe at any time by clicking on the link at the bottom of our
          emails.
        </label>
      </fieldset>
      {formSent === 'initial' ?
      (
        <button
          className="!block max-w-40 button button--slim button--forest w-full"
          type="submit"
          >
          Get a demo
        </button>
      ) : formSent === 'success' ? (
        <div className="flex items-center justify-center">
          <Image className='max-w-[2.75rem] mr-6' image={image.localFile.publicURL} />
          <p className="font-bold md:text-[18px]">Thank you, we will be in touch soon</p>
        </div>
      ) : (
        <p>Form error</p>
      )}
    </form>
    );
};