import React from 'react';
import { Image } from '~/components/elements/Image';
import { Link } from '~/components/elements/Link';
import { PrimaryTextColour } from '~/components/utility/TextColour';
import { CardStackCard } from '~/components/flexible/CardStackCard';

export const CardStack = ({ cards }) => {
	return (
			<div className="space-y-10 md:space-y-12 lg:space-y-16">
				{cards?.map(({ 
					background,	
					subheading,	
					heading,
					content,
					imageOffset,
					imageWidth,
					image, }, i) =>
									<CardStackCard 
									background={background}
									subheading={subheading}
									heading={heading}
									content={content}
									imageOffset={imageOffset}
									imageWidth={imageWidth}
									image={image}
									key={i}/>
								)}
			</div>
	);
};

