import React, { useState } from 'react';

import { ArticleHero } from '~/components/flexible/ArticleHero';
import { ArticleList } from '~/components/flexible/ArticleList';
import { NewsletterSignup } from '~/components/flexible/NewsletterSignup';
import useResources from '~/hooks/useResources';
import Date from '~/components/elements/Date';

export const Resources = (page) => {
  const featured = page.resources.featured;
  const latestArticles = page.resources.latestArticles;
  const allPosts = useResources();
  const [amount, setPage] = useState(0);
  const perPage = 9;
  const endVisible = 9 + amount * perPage;
  const posts =  useResources().slice(0, endVisible);
  
  return (
    <>
      <ArticleHero
        image={featured.postTypePost.previewImage}
        imageFirst={true}
        imageSize="medium"
        background="bg-pampas"
        preHeading={
          featured.categories?.nodes.map(({ name }, i) => (
            name
         ))
        }
        heading={featured.title}
        headingSize="text-h3"
        link={featured.link}
        date={featured.date}
      />

      <ArticleList columns="3" showCategories={false} listItems={latestArticles} />

      <NewsletterSignup heading={page.resources.ctaHeading} />

      <ArticleList columns="3" showCategories={true} listItems={posts} featurePosts={true} />

      {endVisible < allPosts.length ? (
          <div
            onClick={() => {
              setPage(amount + 1);
            }}
            className="group cursor-pointer pt-6 pb-20 text-center"
          >
            <span className='mt-10 button button--forest'>Load more</span>
          </div>
        ) : (
          <div className="h-[40px]"></div>
        )}
    </>
  );
};
